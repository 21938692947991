import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { AcceptButton, CancelButton } from '../../components/Button/Button';
import { observer } from 'mobx-react';
import { otpRequestStores } from '../stores/OtpRequestStores';
import { Global } from '../../../GlobalClass';
import { FormControl } from 'react-bootstrap';
import * as mobx from 'mobx';
import { user_avatar_default } from '../../../common/images';
class OtpRequestResponseModal extends Component {
    initialState = {
        txt: ''
    }
    constructor(props) {
        super(props);
        this.state = this.initialState;
    };

    onHide = () => {
        otpRequestStores.onChangeOtpRequestResponseModal(false);
        otpRequestStores.setIdOtpRequestToResponse(0);
        this.setState(this.initialState);
    }


    onChangeText = (e) => this.setState({ txt: e.target.value })

    onAcceptChange = () => {
        const { txt } = this.state;
        const { responseOtpRequestAction, onChangeOtpRequestResponseModal, setIdOtpRequestToResponse } = otpRequestStores;
        if (!txt) return Global.error({ message: 'Vui lòng nhập phản hồi.' });
        responseOtpRequestAction(txt);
        onChangeOtpRequestResponseModal(false);
        setIdOtpRequestToResponse(0);
        this.onHide();
    }


    render() {
        const { txt } = this.state;
        return (
            <Modal
                show={otpRequestStores.isShowOtpRequestResponseModal}
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                scrollable
                animation
                onHide={this.onHide}
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
                        {'Phản hồi'}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ maxHeight: '75vh', overflow: 'scroll' }}>
                    <p>  <img alt='' className='img' style={{ width: 50, height: 50, objectFit: 'cover', borderRadius: 25 }} src={otpRequestStores.otpRequestCurentItem.avatar || user_avatar_default} />
                        <b><h3>{otpRequestStores.otpRequestCurentItem.fullname}</h3></b></p>
                    <p>{otpRequestStores.otpRequestCurentItem.otpRequestContent}</p>
                    <img src={otpRequestStores.otpRequestCurentItem.image} style={{
                        maxHeight: '150px'
                    }} />
                    <br />
                    <hr />
                    <FormControl isInvalid={true} isValid={false}
                        value={txt}
                        onChange={this.onChangeText}
                        style={{ minHeight: 100, borderBottom: 'solid 1px #fe805c' }} placeholder="Nhập trả lời của bạn" plaintext as="textarea" />
                </Modal.Body>

                <Modal.Footer>
                    <CancelButton onClick={this.onHide} title='Hủy bỏ' />
                    <AcceptButton onClick={this.onAcceptChange} title='Đồng ý' />
                </Modal.Footer>

            </Modal>
        );
    }
}


export default observer(OtpRequestResponseModal);