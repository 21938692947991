import React, { Component } from 'react';
import PaginationTable from '../../components/PaginationTable';
import { kheBanComplainStore } from '../stores/KheBanComplainStore';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import Dropdown from '../../components/Dropdown';
import moment from 'moment'
import {
    Link
} from "react-router-dom";

const REPORT_STATUS = {
    NEW: 'NEW',
    PROCESSING: 'PROCESSING',
    DONE: 'DONE'
}

const REPORT_STATUS_LIST = [
    {
        id: REPORT_STATUS.NEW,
        name: 'Chưa xử lý'
    },
    {
        id: REPORT_STATUS.PROCESSING,
        name: 'Đang xử lý'
    },
    {
        id: REPORT_STATUS.DONE,
        name: 'Đã xử lý'
    }
]
class ReportListTable extends Component {
    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: kheBanComplainStore.totalFeedbackCount,
            page: kheBanComplainStore.feedbackCurrentPage,
        };
        return paginationOption;
    }

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img alt='' className='img' style={{ width: 80, height: 80 }} src={cell} />
        </div>
    }

    // lockProduct = (cell, row) => {
    //     return <div className="td-actions text-center">
    //         {
    //             !row.statusFeedback ?
    //                 <span className="badge badge-pill badge-danger">Chưa trả lời</span>
    //                 :
    //                 <span className="badge badge-pill badge-success">Đã trả lời</span>
    //         }
    //     </div>
    // }
    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    cell &&
                    <div>
                        <p>{moment(cell).format('hh:mm A')}</p>
                        <p>{moment(cell).format('DD/MM/YYYY')}</p>
                    </div>
                }

            </div>
        );
    }
    // detailFormatter = (cell, row, rowIndex, formatExtraData) => {
    //     return (
    //         <div>
    //             <Link target="_blank" to={'https://khelilac.com/forumthread?id=' + row.threadId}> <button class="btn btn-primary" onClick={() => {
    //             }}>Chi tiết</button></Link>
    //         </div>
    //     );
    // }

    detailFormatter = (cell, row) => {
        return <div className="td-actions text-center" style={{ justifyContent: 'center' }}>
            <a href={"https://api.prod.aladin.today" + row.reportDetail} target="_blank">Chi tiết</a>
        </div>
    }

    onUnBanDevice = (deviceId) => {
        let confirmAlert = window.confirm('Ban có chắc chắn muốn unban thiết bị này?');
        if (confirmAlert) {
            kheBanComplainStore.unbanDevice(deviceId);
        }
    }

    onUnBanUser = (userId) => {
        let confirmAlert = window.confirm('Ban có chắc chắn muốn unban user này?');
        if (confirmAlert) {
            kheBanComplainStore.unbanUser(userId);
        }
    }

    actionButtons = (cell, row) => {
        return <div className="td-actions text-center">
            <button onClick={() => this.onUnBanDevice(row.deviceId)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-danger">
                unban device
            </button>
            <button onClick={() => this.onUnBanUser(row.userId)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-danger">
                unban account
            </button>
        </div>
    };

    fullnameFormarter = (cell, row) => {
        return <div className='text-center'>
            <span>
                <a href={`#${row.toUserId}`} onClick={() => this.props.onShowUserInfo(row.toUserId)}>{row.toUserFullname}</a>
            </span>
            <div className="td-actions text-center">
                <button onClick={this.onFeedbackIconPress(row.toUserId)} style={{ marginTop: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                    <i className="material-icons">feedback</i>
                </button>
            </div>
        </div>
    }
    onFeedbackIconPress = (userIdToFeedback) => () => {
        kheBanComplainStore.changeFeedbackModalStatusWorker(true);
        this.props.onFeedbackIconPress(userIdToFeedback);
    }

    fullnameFormarter2 = (cell, row) => {
        return <div className='text-center'>
            <span>
                <a href={`#${row.fromUserId}`} onClick={() => this.props.onShowUserInfo(row.fromUserId)}>{row.fromUserFullName}</a>
            </span>
            <div className="td-actions text-center">
                <button onClick={this.onFeedbackIconPress(row.fromUserId)} style={{ marginTop: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                    <i className="material-icons">feedback</i>
                </button>
            </div>
        </div>
    }

    onChangeStatus = (row) => (item) => {
        if (row.status == item.id) return;
        kheBanComplainStore.updateReportStatusWorker(row.id, item.id);
    }

    statusFormater = (cell, row) => {
        const buttonClassName = row.status == REPORT_STATUS.DONE ? 'btn-success' : row.status == REPORT_STATUS.PROCESSING ? 'btn-info' : 'btn-danger'
        return <div className="td-actions text-center">
            <Dropdown
                buttonClassName={buttonClassName}
                onItemClicked={this.onChangeStatus(row)}
                idSelected={row.status}
                datas={REPORT_STATUS_LIST}
            />
        </div>
    }

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: 'id',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 20
                }
            },
            {
                dataField: 'description',
                text: 'description',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'createdAt',
                text: 'Khiếu nại ngày',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'deviceId',
                text: 'DeviceId',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'userId',
                text: 'Bị ban do UserId',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'displayName',
                text: 'Tên TK bị ban liên quan',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'accountBannedAt',
                text: 'TK bị ban ngày',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'deviceBannedAt',
                text: 'Device bị ban ngày',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            // {
            //     dataField: 'status',
            //     text: 'Trạng thái',
            //     formatter: this.statusFormater,
            //     headerFormatter: this.headerFormatter,
            //     headerStyle: {
            //         width: 80,
            //         textAlign: 'center'
            //     }
            // },
            {
                dataField: 'reportDetail',
                text: 'Chi tiết về các báo cáo',
                formatter: this.detailFormatter,
                headerStyle: {
                    width: 48,
                    textAlign: 'center'
                }
            },
            {
                dataField: 'groupAction',
                text: 'UnBan',
                formatter: this.actionButtons,
                headerStyle: {
                    width: 48,
                    textAlign: 'center'
                }
            }
        ];
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 60,
        textAlign: 'left'
    })

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        kheBanComplainStore.setPage(page);
        kheBanComplainStore.getData();
    }

    render() {
        const reports = toJS(kheBanComplainStore).reportlist;
        return (
            <PaginationTable
                paginationOptions={this.paginationOption()}
                onTableChange={this.onTableChange}
                datas={reports}
                columns={this.columnsOption()}
                loading={kheBanComplainStore.loading}
            />
        );
    }
}

export default observer(ReportListTable);