import { getLocalStorage, setLocalStorage, LOCAL_STORAGE_TYPE } from '../common/localStorage';
import { appStores } from '../stores/AppStores';
import { Global } from '../GlobalClass';
import numeral from 'numeral';
import { setTokenAxios } from '../api/axios';
import { ROUTER } from '../common/route';

// NDT's file
const jwt_decode = require('jwt-decode');

export const decodeJwtToken = (token) => {
    if (!token) return {};
    try {
        const decoded = jwt_decode(token);
        return decoded;
    } catch (error) {
        return {};
    }
}


export const handleAuthMiddleware = () => {
    const userToken = getLocalStorage(LOCAL_STORAGE_TYPE.USER_TOKEN);
    if (!!userToken) {
        const userInfo = decodeJwtToken(userToken);
        const { setToken, setUserInfo } = appStores;
        setToken(userToken);
        setTokenAxios(userToken)
        setUserInfo(userInfo);
        onSetSideBars();
        return true;
    } else {
        // set path to redirect after login
        const currentUrl = Global.currentUrl();
        if (!currentUrl.includes('/Login')) {
            Global.replaceRefresh('/Login');
            setLocalStorage(LOCAL_STORAGE_TYPE.PATH_TO_REDIRECT, currentUrl);
        }
        return false;
    }
}

export const onSetSideBars = () => {
    const { setSideBars, userInfor } = appStores;
    let sideBarsTemp = [];
    if (userInfor.group == 'administrator')
        sideBarsTemp = [
            {
                title: 'Dashboard',
                icon: 'dashboard',
                to: ROUTER.INDEX,
                id: 0
            },
            {
                title: 'Số liệu thống kê',
                icon: 'dashboard',
                to: ROUTER.STATS.INDEX,
                id: 21
            },
            {
                title: 'Quản lý sản phẩm',
                icon: 'playlist_add_check',
                to: ROUTER.PRODUCT.INDEX,
                id: 1
            },
            {
                title: 'Quản lý đơn hàng',
                icon: 'receipt',
                to: ROUTER.ORDER.INDEX,
                id: 18
            },
            {
                title: 'Quản lý người dùng',
                icon: 'contacts',
                to: ROUTER.USER.INDEX,
                id: 2
            },
            {
                title: 'Quản lý Collection',
                icon: 'group',
                to: ROUTER.COLLECTION.INDEX,
                id: 15
            },
            {
                title: 'Forum',
                icon: 'forum',
                to: ROUTER.FORUM.INDEX,
                id: 16
            },

            // {
            //     title: 'Quản lý liên minh',
            //     icon: 'group',
            //     to: ROUTER.COALITION.INDEX,
            //     id: 13
            // },
            {
                title: 'Phản hồi từ người dùng',
                icon: 'feedback',
                to: ROUTER.FEEDBACK.INDEX,
                id: 3
            },
            {
                title: 'Báo cáo từ người dùng',
                icon: 'report',
                to: ROUTER.REPORT.INDEX,
                id: 4
            },
            {
                title: 'Báo cáo sản phẩm',
                icon: 'report',
                to: ROUTER.PRODUCT_REPORT.INDEX,
                id: 5
            },
            {
                title: 'Báo cáo bài viết',
                icon: 'report',
                to: ROUTER.KHE_THREAD_REPORT.INDEX,
                id: 22
            },
            {
                title: 'Báo cáo bình luận',
                icon: 'report',
                to: ROUTER.KHE_COMMENT_REPORT.INDEX,
                id: 23
            },
            {
                title: 'Báo cáo private chat',
                icon: 'report',
                to: ROUTER.KHE_PRIVATE_CHAT_REPORT.INDEX,
                id: 24
            },
            {
                title: 'Báo cáo group chat',
                icon: 'report',
                to: ROUTER.KHE_GROUP_CHAT_REPORT.INDEX,
                id: 25
            },
            {
                title: 'Báo cáo profile',
                icon: 'report',
                to: ROUTER.KHE_PROFILE_REPORT.INDEX,
                id: 26
            },
            {
                title: 'Danh sách user bị báo cáo',
                icon: 'report',
                to: ROUTER.KHE_REPORT.INDEX,
                id: 27
            },
            {
                title: 'Danh sách khiếu nại ban',
                icon: 'report',
                to: ROUTER.KHE_BAN_COMPLAIN.INDEX,
                id: 28
            },
            {
                title: 'Yêu cầu OTP quên mật khẩu',
                icon: 'feedback',
                to: ROUTER.OTP_REQUEST.INDEX,
                id: 29
            },
            {
                title: 'Banner quảng cáo',
                icon: 'chrome_reader_mode',
                to: ROUTER.ADVERTISING.INDEX,
                id: 6
            },
            {
                title: 'User quên mật khẩu',
                icon: 'lock_open',
                to: ROUTER.FORGOT_PASSWORD_PHONE_NUMBER.INDEX,
                id: 7
            },
            {
                title: 'Quản lý Intro images',
                icon: 'view_carousel',
                to: ROUTER.INTRO_IMAGES.INDEX,
                id: 8
            },
            {
                title: 'Push notification',
                icon: 'send',
                to: ROUTER.PUSH_NOTIFICATION.INDEX,
                id: 9
            },
            {
                title: 'Sự kiện',
                icon: 'backup_table',
                to: ROUTER.EVENTS.INDEX,
                id: 17
            },
            {
                title: 'Promotion Popup',
                icon: 'campaign',
                to: ROUTER.PROMOTION_POPUP.INDEX,
                id: 20
            },
            {
                title: 'School',
                icon: 'view_carousel',
                to: ROUTER.SCHOOL.INDEX,
                id: 30
            },
            {
                title: 'School Request',
                icon: 'view_carousel',
                to: ROUTER.SCHOOL_REQUEST.INDEX,
                id: 31
            },
            {
                title: 'Gửi email',
                icon: 'email',
                to: ROUTER.SEND_EMAIL.INDEX,
                id: 14
            },
            {
                title: 'Quảng cáo',
                icon: 'monetization_on',
                to: ROUTER.MANAGE_ADVERTISING.INDEX,
                id: 10
            },
            {
                title: 'Bài viết',
                icon: 'monetization_on',
                to: ROUTER.MANAGE_ARTICLE.INDEX,
                id: 11
            },
            {
                id: 12,
                title: 'Biểu đồ',
                icon: 'bar_chart',
                to: ROUTER.CHART.INDEX,
                children: [
                    {
                        id: 12.1,
                        title: 'Số lượng "Ask"',
                        to: ROUTER.CHART_ASK.INDEX,
                    },
                    {
                        id: 12.2,
                        title: 'Số lượng "Make an Offer"',
                        to: ROUTER.CHART_MAKE_AN_OFFER.INDEX,
                    },
                    {
                        id: 12.3,
                        title: 'Số lượng "Chat message"',
                        to: ROUTER.CHART_CHAT_MESSAGE.INDEX,
                    },
                    {
                        id: 12.4,
                        title: 'Số lượng "Tiền bán"',
                        to: ROUTER.CHART_MONEY_SOLD.INDEX,
                    },
                    {
                        id: 12.5,
                        title: 'Số lượng "Mặt hàng đang bán"',
                        to: ROUTER.CHART_SELLING_PRODUCTS.INDEX,
                    },
                    {
                        id: 12.6,
                        title: 'Số lượng "Mặt hàng đã bán"',
                        to: ROUTER.CHART_PRODUCT_SOLD.INDEX,
                    },
                    {
                        id: 12.7,
                        title: 'Số lượng "Mặt hàng đã bán theo loại"',
                        to: ROUTER.CHART_PRODUCT_SELL_THREE_TYPES.INDEX,
                    },
                    {
                        id: 12.8,
                        title: 'Số lượng user theo quận',
                        to: ROUTER.CHART_REPORT_USER_BY_LOCATION.INDEX,
                    }

                ]
            }
        ];
    else sideBarsTemp = [
        {
            title: 'Quản lý sản phẩm',
            icon: 'playlist_add_check',
            to: ROUTER.PRODUCT.INDEX,
            id: 1
        },
        {
            title: 'Quản lý sản phẩm',
            icon: 'playlist_add_check',
            to: ROUTER.ORDER.INDEX,
            id: 17
        },
        {
            title: 'Quản lý người dùng',
            icon: 'contacts',
            to: ROUTER.USER.INDEX,
            id: 2
        },
        {
            title: 'Phản hồi từ người dùng',
            icon: 'feedback',
            to: ROUTER.FEEDBACK.INDEX,
            id: 3
        },
        {
            title: 'Báo cáo từ người dùng',
            icon: 'report',
            to: ROUTER.REPORT.INDEX,
            id: 4
        },
        {
            title: 'Báo cáo sản phẩm',
            icon: 'report',
            to: ROUTER.PRODUCT_REPORT.INDEX,
            id: 5
        },
        {
            title: 'Forum',
            icon: 'forum',
            to: ROUTER.FORUM.INDEX,
            id: 16
        },
        {
            title: 'Quản lý Collection',
            icon: 'group',
            to: ROUTER.COLLECTION.INDEX,
            id: 15
        },
    // {
        //     title: 'Quản lý liên minh',
        //     icon: 'group',
        //     to: ROUTER.COALITION.INDEX,
        //     id: 13
        // },
    ];
    setSideBars(sideBarsTemp);
    return sideBarsTemp;
}

export function formatCurrency(price) {
    return numeral(price).format('0,0');
}


export const pageableOffset = (page) => (page - 1) * 10;

export const pageableOffsetWithLimit = (page, limit) => (page - 1) * limit;

export const getParamURL = (props, key) => {
    const params = new URLSearchParams(props.location.search);
    const data = params.get(key);
    return data;
}

export const getBooleanListVerifications = (user) => {
    if (!user) return [];
    const verificationItems = [{
        id: 1,
        img: 'email',
        status: false,
        key: 'verifyEmail'
    },
    {
        id: 2,
        img: 'user',
        status: false,
        key: 'verifyAvatar'
    },
    {
        id: 3,
        img: 'call',
        status: false,
        key: 'verifyPhoneNumber'
    },
    {
        id: 4,
        img: 'id_card',
        status: false,
        key: 'verifyId'
    },
    {
        id: 5,
        img: 'facebook_text',
        status: false,
        key: 'verifyFacebook'
    }];
    verificationItems.map(o => {
        if (o.key == 'verifyId') {
            o.status = true;
        } else o.status = user[o.key] || false;
    })
    return verificationItems;
}