import { extendObservable } from 'mobx';
import { Global } from '../../../GlobalClass';

import { getSchoolRequestList, getSchoolRequestCount, getSchoolRequestDetails, createSchoolRequest, approveSchoolRequest, rejectSchoolRequest } from '../../../api/schoolRequest/schoolRequest';
import { pageableOffset } from 'src/helpers/CF';

class SchoolRequestStore {
  constructor() {
    extendObservable(this, {
      isLoadingGetSchoolRequestList: false,
      schoolRequestList: [],
      schoolRequestCount: 0,
      currentSchoolRequest: {},
      tableCurrentPage: 1,
      isShowModal: false,
      txtSearch: ''
    })
  }

  onTextSearchChange = (txt) =>this.txtSearch = txt || '';

  clearCurrentSchoolRequest = () => this.currentSchoolRequest = {};

  setShowModal = isShow => this.isShowModal = isShow;

  setTableCurrentPage = page => {
    this.tableCurrentPage = page;
  }

  getSchoolRequestCount = async () => {
    try {
      this.isLoadingGetSchoolRequestList = true;
      const result = await getSchoolRequestCount(this.txtSearch || '');
      if (result.responseCode.toString().startsWith('2')) {
        this.schoolRequestCount = result.data && result.data.count || 0;
      } else {
        Global.error({ message: result.responseText || '' });
      }
      this.isLoadingGetSchoolRequestList = false;
    } catch (error) {
      Global.error({ message: error.message || '' });
      this.isLoadingGetSchoolRequestList = false;
    }
  }

  getSchoolRequestList = async () => {
    try {
      this.isLoadingGetSchoolRequestList = true;
      const offset = pageableOffset(this.tableCurrentPage);
      const result = await getSchoolRequestList(this.txtSearch || '', 10, offset);
      if (result.responseCode.toString().startsWith('2')) {
        this.schoolRequestList = result.dataArray || [];
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
    this.isLoadingGetSchoolRequestList = false;
  }

  getSchoolRequestDetails = async (schoolRequestId) => {
    try {
      this.isLoadingGetSchoolRequestList = true;
      const result = await getSchoolRequestDetails(schoolRequestId);
      if (result.responseCode.toString().startsWith('2')) {
        this.currentSchoolRequest = result.data || {};
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
    this.isLoadingGetSchoolRequestList = false;
  }

  approveSchoolRequest = async (schoolRequestId, payload) => {
    try {
      const result = await approveSchoolRequest(schoolRequestId, payload);
      if (result.responseCode.toString().startsWith('2')) {
        this.clearCurrentSchoolRequest();
        this.isShowModal = false;
        this.getSchoolRequestList();
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
  }

  createSchoolRequest = async (payload) => {
    try {
      const result = await createSchoolRequest(payload);
      if (result.responseCode.toString().startsWith('2')) {
        this.clearCurrentSchoolRequest();
        this.isShowModal = false;
        this.getSchoolRequestList();
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
  }

  rejectSchoolRequest = async (schoolRequestId, payload) => {
    try {
      const result = await rejectSchoolRequest(schoolRequestId, payload);
      if (result.responseCode.toString().startsWith('2')) {
        this.clearCurrentSchoolRequest();
        this.isShowModal = false;
        this.getSchoolRequestList();
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
  }
}

export default new SchoolRequestStore();