import React, { Component, createRef } from 'react';
import KheProfileReportListTable from './components/KheProfileReportListTable';
import { kheProfileReportStore } from './stores/KheProfileReportStore';
import { userDetailStores } from '../UserManagement/stores/UserDetailStores';
import UserDetailModal from '../UserManagement/components/UserDetail/UserDetailModal';
import KheProfileReportFeedbackModal from './components/KheProfileReportFeedbackModal';

class KheProfileReportList extends Component {
    constructor(props) {
        super(props);
        this.kheProfileReportFeedbackModalRef = createRef();
    };

    onShowUserInfo = (userId) => {
        if (!userId) return;
        userDetailStores.setUserIdForLoadDetail(userId);
        userDetailStores.onChangeUserDetailModal(true);
    }

    onFeedbackIconPress = (userIdToSend) => {
        if (this.kheProfileReportFeedbackModalRef.current) {
            this.kheProfileReportFeedbackModalRef.current.settUserIdToSendFeedback(userIdToSend);
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <KheProfileReportListTable
                        onFeedbackIconPress={this.onFeedbackIconPress}
                        onShowUserInfo={this.onShowUserInfo}
                    />
                </div>
                <UserDetailModal />
                <KheProfileReportFeedbackModal
                    ref={this.kheProfileReportFeedbackModalRef} />
            </div>
        );
    }

    componentDidMount = async () => {
        kheProfileReportStore.getData();
    };

}

export default KheProfileReportList;