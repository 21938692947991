import React, { Component } from 'react';
import { observer } from 'mobx-react';
// import {
//     Link
// } from "react-router-dom";
// import { ROUTER } from '../../common/route';
// import { toJS } from 'mobx';
// import * as mobx from 'mobx';
// import { Button } from 'react-bootstrap';


class Stats extends Component {
    constructor(props) {
        super(props);
    };


    render() {

        return (
            <div className='content'>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLocaleStats" target="_blank">01. Thông số của các địa phương</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getQKUserNumber" target="_blank">02. Số lượng thành viên Quả Khế</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getQKProductNumber" target="_blank">03. Số mặt hàng được bán từ thành viên Quả Khế</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getQKAskNumber" target="_blank">04. Số lượt hỏi mua từ thành viên Quả Khế</a>
                {/* <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getGroupNumber" target="_blank">05. Số hội đang hoạt động trên các địa phương</a> */}
                {/* <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getEventTetStats" target="_blank">06. Thống kê các chỉ số của event Tết</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getEventTopEnergy" target="_blank">07. Top 100 hoạt động event</a> */}
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getA1Stats" target="_blank">08. Thống kê chỉ số A1</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLocaleA1Stats" target="_blank">09. Thống kê các chỉ số A1 của địa phương</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getTopInviteFriend" target="_blank">10. Top 100 mời bạn</a>
                {/* <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getEventDBNLVStats" target="_blank">11. Thống kê các chỉ số của event Bán Ngay Nhận Lộc Vàng</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getEventXNLStats" target="_blank">12. Thống kê các chỉ số của event Xoay Nhận Lộc</a> */}
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getConvertRates60" target="_blank">13. Số lượng users, sellers, buyers, 1st askers, 1st success askers trong 2 tháng</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLastSellers" target="_blank">14. Thông tin của những người bán gần nhất</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLastBuyers" target="_blank">15. Thông tin của những người mua (từng mua) gần nhất</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLastChatConversations" target="_blank">16. Các cuộc hội thoại gần nhất</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLastActiveSellers7" target="_blank">17. Thông tin của những người bán còn hoạt động trong 7 ngày</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLastActiveBuyers7" target="_blank">18. Thông tin của những người mua (từng mua) còn hoạt động trong 7 ngày</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLastInactiveSellers30" target="_blank">19. Thông tin của những người bán không còn hoạt động trong 30 ngày</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLastInactiveBuyers30" target="_blank">20. Thông tin của những người mua (từng mua) không còn hoạt động trong 30 ngày</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getRewardConfig" target="_blank">21. Xem cách cho điểm của Karma</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getTop100Reward" target="_blank">22. Danh sách 100 users đóng góp nhiều nhất (theo điểm Karma)</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbStats" target="_blank">23. Thống kê chỉ số của LBB</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getActiveLbbStats" target="_blank">24. Thống kê chỉ số của Active LBB</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/get24hLbbStats" target="_blank">25. Thống kê chỉ số của LBB trong 24h qua</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getDailyLbbStats" target="_blank">26. So sánh chỉ số của LBB theo ngày</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getDailyLbbStats2" target="_blank">27. So sánh chỉ số của LBB theo ngày (2)</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getDailyLbbPostStats" target="_blank">28. Thống kê số người đăng của LBB theo ngày</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getDailyLbbFirstPostStats" target="_blank">29. Chỉ số về thời lượng đăng bài thứ 1 sau khi tạo tài khoản theo ngày</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getWeeklyLbbFirstPostStats" target="_blank">30. Chỉ số về thời lượng đăng bài thứ 1 sau khi tạo tài khoản theo tuần</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbChatStats" target="_blank">31. Thống kê số lượng chat conversation toàn hệ thống</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getDailyLbbChatStats" target="_blank">32. Thống kê số lượng chat conversation theo ngày</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbChatStatsWithDistance" target="_blank">33. Thống kê số lượng chat conversation cùng với khoảng cách</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getWeeklyLbbChatStatsWithDistance" target="_blank">34. Thống kê số lượng chat conversation theo tuần cùng với khoảng cách</a>
                {/* <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbThreadLocations" target="_blank">35. Phân bố LBB chia sẻ trên bản đồ</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbUserLocations" target="_blank">36. Phân bố LBB users trên bản đồ</a> */}
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbActiveUserLocations" target="_blank">37. Phân bố LBB active users trên bản đồ (user vừa online trong 30 phút)</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbCommentStatsWithDistance" target="_blank">38. Thống kê số lượng comment cùng với khoảng cách</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getWeeklyLbbCommentStatsWithDistance" target="_blank">39. Thống kê số lượng comment theo tuần cùng với khoảng cách</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbTopViewThreadStats" target="_blank">40. Thông tin thống kê về top view LBB threads</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbTopViewActiveThreadStats" target="_blank">41. Thông tin thống kê về top view LBB active threads</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbTopEmojiThreadStats" target="_blank">42. Thông tin thống kê về top emoji LBB threads</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbTopEmojiActiveThreadStats" target="_blank">43. Thông tin thống kê về top emoji LBB active threads</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbTopEmojiThreadStats2" target="_blank">44. Thông tin thống kê về top emoji LBB threads (with comment emoji)</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbTopEmojiActiveThreadStats2" target="_blank">45. Thông tin thống kê về top emoji LBB active threads (with comment emoji)</a>
                {/* <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbHourlyActiveUserStats" target="_blank">46. Thông số về LBB hourly active users</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbHourlyThreadStats" target="_blank">47. Thông tin về số bài đăng và số người đăng theo giờ (trong 7 ngày gần nhất)</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbHourlyCommentStats" target="_blank">48. Thông tin về số bình luận và số người bình luận theo giờ (trong 7 ngày gần nhất)</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbHourlyEmojiStats" target="_blank">49. Thông tin về số emoji và số người gửi theo giờ (trong 7 ngày gần nhất)</a> */}
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbDailyKarmaStatsForA1" target="_blank">50. Thông số về đóng góp của active user mỗi ngày</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getLbbActivityStatsIn24hOfN1" target="_blank">51. Thông số về đóng góp của new user trong 24h (chia theo ngày)</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getTop100ChatGroup" target="_blank">52. Danh sách 100 chat group có nhiều message nhất</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getDayBeforeYestedayNRU" target="_blank">53. Danh sách NRU ngày hôm kia</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getApiResponseTimeStatsOfLastWeek" target="_blank">54. Thống kê tốc độ API trong tuần</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getDailyCoinReport" target="_blank">55. Thống kê sử dụng vàng theo ngày</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getDonateUsers" target="_blank">56. Danh sách user ủng hộ Khế</a>
                {/* <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getJun01Users" target="_blank">57. Danh sách user bình luận bài 1/6</a> */}
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getHashtagStats" target="_blank">58. Thống kê sử dụng hashtag theo ngày</a>
                <br/>
                <a href="https://api.prod.aladin.today/public/remoteLog/getDeviceInfo?displayName=" target="_blank">59. Xem thông tin về điện thoại user đang dùng cùng app version</a>
            </div>
        );
    }

}

export default observer(Stats)