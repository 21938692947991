import React, { Component, createRef } from 'react';
import KheGroupChatReportListTable from './components/KheGroupChatReportListTable';
import { kheGroupChatReportStore } from './stores/KheGroupChatReportStore';
import { userDetailStores } from '../UserManagement/stores/UserDetailStores';
import UserDetailModal from '../UserManagement/components/UserDetail/UserDetailModal';
import KheGroupChatReportFeedbackModal from './components/KheGroupChatReportFeedbackModal';

class KheGroupChatReportList extends Component {
    constructor(props) {
        super(props);
        this.kheGroupChatReportFeedbackModalRef = createRef();
    };

    onShowUserInfo = (userId) => {
        if (!userId) return;
        userDetailStores.setUserIdForLoadDetail(userId);
        userDetailStores.onChangeUserDetailModal(true);
    }

    onFeedbackIconPress = (userIdToSend) => {
        if (this.kheGroupChatReportFeedbackModalRef.current) {
            this.kheGroupChatReportFeedbackModalRef.current.settUserIdToSendFeedback(userIdToSend);
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <KheGroupChatReportListTable
                        onFeedbackIconPress={this.onFeedbackIconPress}
                        onShowUserInfo={this.onShowUserInfo}
                    />
                </div>
                <UserDetailModal />
                <KheGroupChatReportFeedbackModal
                    ref={this.kheGroupChatReportFeedbackModalRef} />
            </div>
        );
    }

    componentDidMount = async () => {
        kheGroupChatReportStore.getData();
    };

}

export default KheGroupChatReportList;