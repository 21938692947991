import { requestApi } from "../axios";
const prefix = 'otpRequest';

export function getOtpRequestListApi(offset) {
    return requestApi(`${prefix}/list?limit=${10}&offset=${offset}`, 'GET');
}

export function getOtpRequestTotalCountApi() {
    return requestApi(`${prefix}/total-count`, 'GET');
}

export function responseOtpRequestApi(id, message) {
    return requestApi(`${prefix}/response`, 'POST', { id, message });
}

export function updateOtpRequestStatusApi(id, status) {
    return requestApi(`${prefix}/update-status`, 'PUT', { id, status });
}