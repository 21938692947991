import { requestApi } from "../axios";
const prefix = 'khe-report';

export function getKheReportList(offset) {
    return requestApi(`${prefix}/list?limit=${10}&offset=${offset}`, 'GET');
}

export function banUserApi(userId) {
    return requestApi(`/ban/ban-account`, 'POST', { userId });
}

export function updateKheProfileStatusApi(id, status) {
    return requestApi(`${prefix}/update-status`, 'PUT', { id, status });
}

export function sendFeedbackKheReportApi(content, userIdToSend, metadata) {
    return requestApi(`${prefix}/send-feedback-report`, 'POST', {
        content, userIdToSend, metadata
    });
}