import { extendObservable } from 'mobx';
import { Global } from '../../../GlobalClass';

import { getSchoolList, getSchoolCount, getSchoolDetails, createSchool, updateSchool, deleteSchool } from '../../../api/school/school';
import { pageableOffset } from 'src/helpers/CF';

class SchoolStore {
  constructor() {
    extendObservable(this, {
      isLoadingGetSchoolList: false,
      schoolList: [],
      schoolCount: 0,
      currentSchool: {},
      tableCurrentPage: 1,
      isShowModal: false,
      txtSearch: ''
    })
  }

  onTextSearchChange = (txt) =>this.txtSearch = txt || '';

  clearCurrentSchool = () => this.currentSchool = {};

  setShowModal = isShow => this.isShowModal = isShow;

  setTableCurrentPage = page => {
    this.tableCurrentPage = page;
  }

  getSchoolCount = async () => {
    try {
      this.isLoadingGetSchoolList = true;
      const result = await getSchoolCount(this.txtSearch || '');
      if (result.responseCode.toString().startsWith('2')) {
        this.schoolCount = result.data && result.data.count || 0;
      } else {
        Global.error({ message: result.responseText || '' });
      }
      this.isLoadingGetSchoolList = false;
    } catch (error) {
      Global.error({ message: error.message || '' });
      this.isLoadingGetSchoolList = false;
    }
  }

  getSchoolList = async () => {
    try {
      this.isLoadingGetSchoolList = true;
      const offset = pageableOffset(this.tableCurrentPage);
      const result = await getSchoolList(this.txtSearch || '', 10, offset);
      if (result.responseCode.toString().startsWith('2')) {
        this.schoolList = result.dataArray || [];
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
    this.isLoadingGetSchoolList = false;
  }

  getSchoolDetails = async (schoolId) => {
    try {
      this.isLoadingGetSchoolList = true;
      const result = await getSchoolDetails(schoolId);
      if (result.responseCode.toString().startsWith('2')) {
        this.currentSchool = result.data || {};
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
    this.isLoadingGetSchoolList = false;
  }

  updateSchool = async (schoolId, payload) => {
    try {
      const result = await updateSchool(schoolId, payload);
      if (result.responseCode.toString().startsWith('2')) {
        this.clearCurrentSchool();
        this.isShowModal = false;
        this.getSchoolList();
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
  }

  createSchool = async (payload) => {
    try {
      const result = await createSchool(payload);
      if (result.responseCode.toString().startsWith('2')) {
        this.clearCurrentSchool();
        this.isShowModal = false;
        this.getSchoolList();
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
  }

  deleteSchool = async (schoolId, payload) => {
    try {
      const result = await deleteSchool(schoolId, payload);
      if (result.responseCode.toString().startsWith('2')) {
        this.clearCurrentSchool();
        this.isShowModal = false;
        this.getSchoolList();
      } else {
        Global.error({ message: result.responseText || '' });
      }
    } catch (error) {
      Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    }
  }
}

export default new SchoolStore();