import { extendObservable } from 'mobx';
import { pageableOffset } from '../../../helpers/CF';
import { getKheProfileReportList, /*deleteGroupApi,*/ updateKheProfileStatusApi, sendFeedbackKheProfileReportApi } from '../../../api/kheProfileReport/kheProfileReport';
import { Global } from '../../../GlobalClass';
class KheProfileReportStore {
    constructor() {
        extendObservable(this, {
            reportlist: [],
            loading: false,
            currentPage: 1,
            isShowFeedbackModal: false,
            sendFeedbackLoading: false
        });
    }


    getData = async () => {
        try {
            this.loading = true;
            const offset = pageableOffset(this.currentPage);
            const result = await getKheProfileReportList(offset);
            if (result.responseCode.toString().startsWith('2')) {
                this.reportlist = result.dataArray || [];
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
        this.loading = false;
    }

    updateReportStatusWorker = async (id, status) => {
        this.loading = true;
        try {
            const result = await updateKheProfileStatusApi(id, status);
            if (result.responseCode.toString().startsWith('2')) {
                const index = this.reportlist.findIndex(o => o.id == id);
                if (index > -1) {
                    this.reportlist[index].status = status;
                }
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
        this.loading = false;
    }

    sendFeedbackReportWorker = async (content, userIdToSend, metadata) => {
        try {
            this.changeFeedbackModalLoadingWorker(true);
            const result = await sendFeedbackKheProfileReportApi(content, userIdToSend, metadata);
            if (result.responseCode.toString().startsWith('2')) return true;
            else Global.error({ message: result.responseText || '' });
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
        this.changeFeedbackModalLoadingWorker(false);
        return false;
    }

    // onChangeFeedbackResponseModal = (status) => this.isShowFeedbackResponseModal = status;

    // setIdFeedbackToResponse = (feedbackId) => this.idFeedbackToResponse = feedbackId;

    setPage = (page) => this.currentPage = page;

    changeFeedbackModalStatusWorker = (status) => this.isShowFeedbackModal = status;
    changeFeedbackModalLoadingWorker = (status) => this.sendFeedbackLoading = status;

    // responseFeedbackAction = async (message) => {
    //     const result = await responseFeedbackApi(this.idFeedbackToResponse, message);
    //     if (result.responseCode.toString().startsWith('2')) this.getFeedbackListAction();
    //     else Global.error({ message: result.responseText || '' });
    // }

    // deleteGroupWorker = async (groupId) => {
    //     try {
    //         this.changeFeedbackModalLoadingWorker(true);
    //         const result = await deleteGroupApi(groupId);
    //         if (result.responseCode.toString().startsWith('2')) {
    //             const currList = [...this.reportlist];
    //             const index = currList.findIndex(o => o.groupId == groupId);
    //             if (index > -1) {
    //                 currList.splice(index, 1);
    //                 this.reportlist = currList;
    //             }

    //             this.changeFeedbackModalLoadingWorker(false);
    //             return true;
    //         } else Global.error({ message: result.responseText || '' });
    //     } catch (error) {
    //         Global.error({ message: error.message || 'Có lỗi xảy ra.' });
    //         this.changeFeedbackModalLoadingWorker(false);
    //     }

    //     return false;
    // }
}
export const kheProfileReportStore = new KheProfileReportStore();