import { requestApi } from "../axios";
const prefix = 'khe-report/thread-report';

export function getKheThreadReportList(offset) {
    return requestApi(`${prefix}/list?limit=${10}&offset=${offset}`, 'GET');
}

export function updateKheThreadStatusApi(id, status) {
    return requestApi(`${prefix}/update-report-status`, 'PUT', { id, status });
}

export function sendFeedbackKheThreadReportApi(content, userIdToSend, metadata) {
    return requestApi(`${prefix}/send-feedback-report`, 'POST', {
        content, userIdToSend, metadata
    });
}