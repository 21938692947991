import { toJS } from 'mobx';
import React, { useCallback, useMemo } from 'react';
import { threadListStores } from '../stores/ThreadListStores';
import moment from 'moment'
import PaginationTable from '../../components/PaginationTable';
import { user_avatar_default } from 'src/common/images';
import { observer } from 'mobx-react';
import { Switch } from '@material-ui/core';
import { shortenDesc } from 'src/helpers/shortenDesc';

const ThreadListTable = ({
    searchKeyword,
    pinned,
    categoryId
}) => {
    const {
        totalThreadListCount,
        threadTableCurrentPage,
        setThreadTablePage,
        getThreadList,
        threadList,
        getThreadListLoading,
        updatePinWorker,
        setThreadDetailInfo,
        setThreadDetailModalStatus,
        categorySelected,
        threadCategories,
        getThreadCategories,
        deleteThreadWorker
    } = toJS(threadListStores);

    const paginationOption = useMemo(() => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: totalThreadListCount,
            page: threadTableCurrentPage,
        };
        return paginationOption;
    }, [totalThreadListCount, threadTableCurrentPage]);

    const headerFormatter = useCallback((column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }, []);

    const headerStyleDefault = useCallback(() => ({
        width: 100,
    }), []);

    const onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        setThreadTablePage(page);
        getThreadList(pinned ? undefined : categorySelected.id, searchKeyword, pinned);
    }

    // const statusFormater = useCallback((cell, row) => {
    //     return <div>
    //         {
    //             !!row.deletedAt ?
    //                 <span className="badge badge-pill badge-danger">Đã xóa</span>
    //                 :
    //                 <span className="badge badge-pill badge-success">Hoạt động</span>
    //         }
    //     </div>
    // }, []);

    const onDeleteThread = (forumId) => {
        let confirmAlert = window.confirm('Ban có chắc chắn muốn xóa?');
        if (confirmAlert) {
            deleteThreadWorker(forumId);
        }
    }

    const thumbFormatter = useCallback((cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img className='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 40 }} src={row.creatorAvatar || user_avatar_default} />
        </div>
    }, []);

    const descFormatter = useCallback((cell, row) => {
        return <div>
            {shortenDesc(cell)}
        </div>
    }, [])

    const dateFormatter = useCallback((cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                <span>{moment(cell).format('HH:mm - DD/MM/YYYY')}</span>
            </div>
        );
    }, []);

    const actionButtons = useCallback((cell, row) => {
        return <div className="td-actions text-center">
            <button onClick={() => onDeleteThread(row.id)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-danger">
                <i className="material-icons">delete</i>
            </button>
            <button onClick={() => onShowInfo(row)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                <i className="material-icons">edit</i>
            </button>
        </div>
    }, [threadCategories]);

    const switchFormatter = useCallback((cell, row) => {
        return (
            <div className="td-actions text-left">
                <Switch
                    onChange={(e, checked) => {
                        updatePinWorker({ ...row, threadId: row.id, pinned: !!checked });
                    }}
                    checked={!!row.pinned} />
            </div>
        )
    }, []);

    const columnsOption = useMemo(() => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'creatorAvatar',
                text: 'Avatar',
                formatter: thumbFormatter,
                headerFormatter: headerFormatter,
                headerStyle: headerStyleDefault
            },
            {
                dataField: 'creatorName',
                text: 'Người đăng',
                headerStyle: headerStyleDefault,
                headerFormatter: headerFormatter,
            },
            {
                dataField: 'title',
                text: 'Tiêu đề',
                headerFormatter: headerFormatter,
                headerStyle: headerStyleDefault,
            },
            {
                dataField: 'description',
                text: 'Mô tả',
                headerFormatter: headerFormatter,
                headerStyle: headerStyleDefault,
                formatter: descFormatter
            },
            // {
            //     dataField: 'deletedAt',
            //     text: 'Trạng thái',
            //     formatter: statusFormater,
            //     headerFormatter: headerFormatter,
            //     headerStyle: { width: 80 }
            // },
            {
                dataField: 'forumCategoryName',
                text: 'Phân loại',
                headerFormatter: headerFormatter,
                headerStyle: headerStyleDefault
            },
            {
                dataField: 'createdAt',
                text: 'Ngày tạo',
                headerFormatter: headerFormatter,
                formatter: dateFormatter,
                headerStyle: headerStyleDefault
            },
            {
                dataField: 'updatedAt',
                text: 'Cập nhật lúc',
                headerFormatter: headerFormatter,
                formatter: dateFormatter,
                headerStyle: headerStyleDefault
            },
            {
                dataField: 'pinned',
                text: 'Trạng thái pin',
                headerFormatter: headerFormatter,
                formatter: switchFormatter,
                headerStyle: headerStyleDefault
            },
            {
                dataField: 'action',
                text: 'Hành động',
                formatter: actionButtons,
                headerFormatter: headerFormatter,
                headerStyle: { width: 80 }
            }
        ];
    }, [threadList, getThreadListLoading]);

    const onShowInfo = useCallback((tempInfo) => {
        const threadCategoriesFilter = threadCategories.map(o => !!o.id);
        if (!threadCategoriesFilter.length) return getThreadCategories();
        setThreadDetailInfo(tempInfo);
        setThreadDetailModalStatus(true);
    }, [threadCategories]);

    return (
        <div className='thread-list-table'>
            {/* <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">
                        <i className='material-icons'>search</i>
                    </span>
                </div>
                <input
                    style={{ marginBottom: 12 }}
                    onChange={this.onChangeText}
                    type="text"
                    value={txtSearchProduct}
                    className="form-control"
                    placeholder="Tìm kiếm theo tên sản phẩm..." />
            </div> */}
            <PaginationTable
                paginationOptions={paginationOption}
                onTableChange={onTableChange}
                datas={threadList}
                columns={columnsOption}
                loading={getThreadListLoading}
            />
        </div>
    )
}

export default observer(ThreadListTable);
