import { requestApi } from "../axios";
const prefix = 'khe-report/private-chat-report';

export function getKhePrivateChatReportList(offset) {
    return requestApi(`${prefix}/list?limit=${10}&offset=${offset}`, 'GET');
}

export function updateKhePrivateChatStatusApi(id, status) {
    return requestApi(`${prefix}/update-report-status`, 'PUT', { id, status });
}

export function sendFeedbackKhePrivateChatReportApi(content, userIdToSend, metadata) {
    return requestApi(`${prefix}/send-feedback-report`, 'POST', {
        content, userIdToSend, metadata
    });
}