import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { user_avatar_default } from 'src/common/images';
import PaginationTable from '../../components/PaginationTable';
import schoolRequestStore from '../stores/SchoolRequestStore';
import EditSchoolRequestModal from './EditSchoolRequestModal';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { COLOR } from 'src/common/constant';
import Loader from 'react-loader-spinner';
import { CancelButton, AcceptButton } from 'src/pages/components/Button';
import { toJS } from 'mobx';

class SchoolRequestTable extends Component {
  constructor() {
    super();
    this.state= {
      isShowConfirmRejectSchoolRequest: false
    }
  }

  headerFormatter = (column) => {
    return <h5><strong>{column.text}</strong></h5>
  }

  formatterImage = (cell, row) => {
    return <div key={row.id} className="card-avatar">
      <img className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={cell || user_avatar_default} />
    </div>
  }

  dateTimeFormatter = (cell) => {
    return (
      <div>
        {
          cell &&
          <div>
            <p>{moment(cell).format('DD/MM/YYYY')}</p>
            <p>{moment(cell).format('hh:mm A')}</p>
          </div>
        }

      </div>
    );
  }

  onApproveClick = async schoolRequestId => {
    const {getSchoolRequestDetails, setShowModal} = schoolRequestStore;
    await getSchoolRequestDetails(schoolRequestId);
    setShowModal(true);
  }

  onRejectClick = async schoolRequestId => {
    const {getSchoolRequestDetails} = schoolRequestStore;
    await getSchoolRequestDetails(schoolRequestId);
    this.setState({
      isShowConfirmRejectSchoolRequest: true,
      reason: null,
      duplicatedSchoolId: null
    })
  }

  actionButtons = (cell, row) => {
    if (row.approved == null) {
      return <div className="td-actions text-center">
        <button onClick={e => this.onApproveClick(row.id)} style={{ marginRight: 6 }} type="button" className="btn btn-info btn-simple">
          <i className="material-icons">add</i>
        </button>
        <button onClick={e => this.onRejectClick(row.id)} style={{ marginRight: 6 }} type="button" className="btn btn-danger btn-simple">
          <i className="material-icons">cancel</i>
        </button>
      </div >
    } else if (row.approved == true) {
      return <div>*APPROVED</div>;
    } else {
      return <div>*REJECTED</div>;
    }
  }

  paginationOption = () => (
    {
        custom: true,
        hideSizePerPage: true,
        totalSize: schoolRequestStore.schoolRequestCount,
        page: schoolRequestStore.tableCurrentPage,
    }
  )

  onTableChange = (type, { page, sizePerPage }) => {
    window.scrollTo(0, 0);
    schoolRequestStore.setTableCurrentPage(page);
    schoolRequestStore.getSchoolRequestList();
  }

  columnsOption = [
    {
      dataField: 'id',
      text: '#',
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 40
      }
    },
    {
      dataField: 'name',
      text: 'Tên',
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 100,
      }
    },
    {
      dataField: 'address',
      text: 'Đại chỉ',
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 80,
      }
    },
    {
      dataField: 'link',
      text: 'Link',
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 80,
      }
    },
    {
      dataField: 'action',
      text: 'Hành động',
      formatter: this.actionButtons,
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 42,
        textAlign: 'center'
      }
    },
    {
      dataField: 'schoolId',
      text: 'School ID',
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 40,
      }
    },
  ]

  onHideConfirmRejectSchoolRequest = () => {
    this.setState({isShowConfirmRejectSchoolRequest: false, reason: null, duplicatedSchoolId: null});
  }

  onConfirmRejectSchoolRequest = async () => {
    const { currentSchoolRequest, rejectSchoolRequest } = schoolRequestStore;
    const { reason, duplicatedSchoolId } = this.state;
    if (!!currentSchoolRequest && !!currentSchoolRequest.id) {
      await rejectSchoolRequest(
        currentSchoolRequest.id,
        { id: currentSchoolRequest.id, reason, duplicatedSchoolId }
      )
    }
    this.setState({
      isShowConfirmRejectSchoolRequest: false,
    })
  }

  render() {
    const { schoolRequestList, isLoadingGetSchoolRequestList, currentSchoolRequest, isShowModal, isLoadingUpdate } = schoolRequestStore;
    const { isShowConfirmRejectSchoolRequest, reason, duplicatedSchoolId } = this.state;
    return (
      <div>
        <Modal show={isShowConfirmRejectSchoolRequest} onHide={this.onHideConfirmRejectSchoolRequest}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Bạn từ chối yêu cầu thêm trường <b>{currentSchoolRequest ? currentSchoolRequest.name + '(' + currentSchoolRequest.id + ')' : ''}</b>?</span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ marginTop: 16, marginBottom: 16 }} >
              <label style={{ marginRight: 16, width: '8%' }} >Lý do: </label>
              <input type='text' style={{ width: '88%', padding: 5 }} onChange={e => this.setState({ reason: e.target.value }) } value={reason} />
            </div>

            <div style={{ marginTop: 16, marginBottom: 16 }} >
              <label style={{ marginRight: 16, width: '28%' }} >Trùng lặp trường ID: </label>
              <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => this.setState({ duplicatedSchoolId: e.target.value }) } value={duplicatedSchoolId} />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isLoadingUpdate ?
              <Loader
                style={{ marginRight: '12px', marginTop: '8px' }}
                type="TailSpin"
                color={COLOR.primary}
                height={30}
                width={30}
              />
              : <div>
                <CancelButton title='HỦY BỎ' onClick={this.onHideConfirmRejectSchoolRequest} />
                <AcceptButton title='XÁC NHẬN' onClick={this.onConfirmRejectSchoolRequest} />
              </div>
            }
          </Modal.Footer>
        </Modal>
        <PaginationTable
          paginationOptions={this.paginationOption()}
          onTableChange={this.onTableChange}
          datas={schoolRequestList}
          columns={this.columnsOption}
          loading={isLoadingGetSchoolRequestList}
        />
        <EditSchoolRequestModal
          isShow={isShowModal}
          onHide={this.onHideModal}
          schoolRequest={currentSchoolRequest}
        />
      </div>
    )
  }
}

export default observer(SchoolRequestTable);