import React, { Component, createRef } from 'react';
import KheCommentReportListTable from './components/KheCommentReportListTable';
import { kheCommentReportStore } from './stores/KheCommentReportStore';
import { userDetailStores } from '../UserManagement/stores/UserDetailStores';
import UserDetailModal from '../UserManagement/components/UserDetail/UserDetailModal';
import KheCommentReportFeedbackModal from './components/KheCommentReportFeedbackModal';

class KheCommentReportList extends Component {
    constructor(props) {
        super(props);
        this.kheCommentReportFeedbackModalRef = createRef();
    };

    onShowUserInfo = (userId) => {
        if (!userId) return;
        userDetailStores.setUserIdForLoadDetail(userId);
        userDetailStores.onChangeUserDetailModal(true);
    }

    onFeedbackIconPress = (userIdToSend) => {
        if (this.kheCommentReportFeedbackModalRef.current) {
            this.kheCommentReportFeedbackModalRef.current.settUserIdToSendFeedback(userIdToSend);
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <KheCommentReportListTable
                        onFeedbackIconPress={this.onFeedbackIconPress}
                        onShowUserInfo={this.onShowUserInfo}
                    />
                </div>
                <UserDetailModal />
                <KheCommentReportFeedbackModal
                    ref={this.kheCommentReportFeedbackModalRef} />
            </div>
        );
    }

    componentDidMount = async () => {
        kheCommentReportStore.getData();
    };

}

export default KheCommentReportList;