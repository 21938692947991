import { extendObservable } from 'mobx';
import {
    getThreadListApi,
    getTotalThreadCountApi,
    updateThreadApi,
    updatePinStatusApi,
    getThreadDetailApi,
    getThreadCategoriesApi,
    createThreadApi,
    deleteThreadApi
} from '../../../api/forum/thread';
import { Global } from '../../../GlobalClass';
import { pageableOffset } from '../../../helpers/CF';
import moment from 'moment';

class ThreadListStores {
    constructor() {
        extendObservable(this, {
            threadList: [],
            getThreadListLoading: false,
            totalThreadListCount: 0,
            threadTableCurrentPage: 1,
            threadDetailModalStatus: false,
            // txtSearchProduct: '',
            categorySelected: {
                id: 0,
                name: 'Tất cả'
            },
            threadCategories: [],
            getThreadCategoriesLoading: false,
            threadDetailInfo: {},
            getThreadDetailLoading: false,
            rightButtonModalLoading: false
        });
    }
    // onSearchProductChange = (txt) => this.txtSearchProduct = txt || '';

    setCategorySelected = (item) => this.categorySelected = item;

    getThreadList = async (categoryId, keyword, pinned) => {
        try {
            this.getThreadListLoading = true;
            const offset = pageableOffset(this.threadTableCurrentPage);
            const result = await getThreadListApi({ offset, categoryId: pinned ? undefined : categoryId, pinned, keyword });
            await this.getThreadDataCount(categoryId, keyword, pinned);
            if (result.responseCode.toString().startsWith('2')) {
                this.threadList = result.dataArray || [];
            } else {
                Global.error({ message: result.responseText || '' });
            }
            this.getThreadListLoading = false;
        } catch (error) {
            Global.error({ message: error.message || '' });
            this.getThreadListLoading = false;
        }
    }
    getThreadCategories = async () => {
        try {
            this.getThreadCategoriesLoading = true;
            const result = await getThreadCategoriesApi();
            this.getThreadCategoriesLoading = false;
            if (result.responseCode.toString().startsWith('2')) {
                this.threadCategories = [{
                    id: 0,
                    title: 'Tất cả'
                // }, ...(result.dataArray || []), {id: result.dataArray.length + 1, title: 'Pinned'}].map(o => ({ id: o.id, name: o.title }));
                }, ...(result.dataArray || [])].map(o => ({ id: o.id, name: o.title }));
        } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || '' });
            this.getThreadCategoriesLoading = false;
        }
    }
    setThreadTablePage = (page) => this.threadTableCurrentPage = page;

    getThreadDataCount = (categoryId, keyword, pinned) => {
        return new Promise(async (resolve, reject) => {
            try {
                const result = await getTotalThreadCountApi(pinned ? undefined : categoryId, keyword, pinned);
                if (result.responseCode.toString().startsWith('2')) {
                    this.totalThreadListCount = parseInt(result.data.count);
                    resolve();
                } else {
                    reject({ message: result.responseText || '' })
                }
            } catch (error) {
                reject(error);
            }
        });
    }

    updatePinWorker = async (data) => {
        try {
            this.getThreadListLoading = true;
            const result = await updatePinStatusApi(data);
            this.getThreadListLoading = false;
            if (result.responseCode.toString().startsWith('2')) {
                const currList = [...this.threadList];
                const index = currList.findIndex(o => o.id == data.threadId);
                if (index > -1) {
                    this.threadList[index].pinned = data.pinned;
                }
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || '' });
            this.getThreadListLoading = false;
        }
    }

    createThreadWorker = ({ title, description, categoryId, imageNames }) => {
        return new Promise(async (resolve, reject) => {
            try {
                this.rightButtonModalLoading = true;
                const result = await createThreadApi({ title, description, categoryId, imageNames });
                this.rightButtonModalLoading = false;
                if (result.responseCode.toString().startsWith('2')) {
                    Global.success({ message: 'Tạo bài viết thành công.' });
                    resolve();
                } else {
                    Global.error({ message: result.responseText || '' });
                }
            } catch (error) {
                Global.error({ message: error.message || '' });
                this.rightButtonModalLoading = false;
            }
        })
    }

    updateThreadWorker = ({ title, description, categoryId, forumId, imageNames }) => {
        return new Promise(async (resolve, reject) => {
            try {
                this.rightButtonModalLoading = true;
                const result = await updateThreadApi({ title, description, categoryId, forumId, imageNames });
                this.rightButtonModalLoading = false;
                if (result.responseCode.toString().startsWith('2')) {
                    const currList = [...this.threadList];
                    const index = currList.findIndex(o => o.id == forumId);
                    if (index > -1) {
                        const index2 = [...this.threadCategories].findIndex(o => o.id == categoryId);
                        const categoryItem = { ...this.threadCategories[index2] };
                        if (index2 > -1) {
                            this.threadList[index] = {
                                ...currList[index],
                                title,
                                description,
                                categoryId,
                                updatedAt: `${moment().toDate().toISOString()}`,
                                forumCategoryName: categoryItem.name
                            };
                        }
                    }
                    Global.success({ message: 'Cập nhật bài viết thành công.' });
                    resolve();
                } else {
                    Global.error({ message: result.responseText || '' });
                }
            } catch (error) {
                Global.error({ message: error.message || '' });
                this.rightButtonModalLoading = false;
            }
        })
    }

    deleteThreadWorker = async (forumId) => {
        try {
            this.getThreadListLoading = true;
            const result = await deleteThreadApi(forumId);
            this.getThreadListLoading = false;
            if (result.responseCode.toString().startsWith('2')) {
                const currList = [...this.threadList];
                const index = currList.findIndex(o => o.id == forumId);
                if (index > -1) {
                    currList.splice(index, 1);
                    this.threadList = currList;
                }
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || '' });
            this.getThreadListLoading = false;
        }
    }

    setThreadDetailModalStatus = (status) => this.threadDetailModalStatus = status;

    getThreadDetailWorker = async (forumId) => {
        try {
            this.getThreadDetailLoading = true;
            const result = await getThreadDetailApi(forumId);
            this.getThreadDetailLoading = false;
            if (result.responseCode.toString().startsWith('2')) {
                this.setThreadDetailInfo(result.data);
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || '' });
        }
    }

    setThreadDetailInfo = (info) => this.threadDetailInfo = info || {};
    setThreadCategories = (data) => this.threadCategories = data || [];

}
export const threadListStores = new ThreadListStores();