import { requestApi } from "../axios";
const prefix = 'khe-report/group-chat-report';

export function getKheGroupChatReportList(offset) {
    return requestApi(`${prefix}/list?limit=${10}&offset=${offset}`, 'GET');
}

export function deleteGroupApi(id) {
    return requestApi(`${prefix}/delete-group`, 'POST', { id });
}

export function updateKheGroupChatStatusApi(id, status) {
    return requestApi(`${prefix}/update-report-status`, 'PUT', { id, status });
}

export function sendFeedbackKheGroupChatReportApi(content, userIdToSend, metadata) {
    return requestApi(`${prefix}/send-feedback-report`, 'POST', {
        content, userIdToSend, metadata
    });
}