import React, { Component } from 'react';
import PaginationTable from '../../components/PaginationTable';
import { otpRequestStores } from '../stores/OtpRequestStores';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { user_avatar_default } from '../../../common/images';
import moment from 'moment';
import Dropdown from '../../components/Dropdown';

const OTP_REQUEST_STATUS = {
    NEW: 'NEW',
    // PROCESSING: 'PROCESSING',
    DONE: 'DONE'
}

const OTP_REQUEST_STATUS_LIST = [
    {
        id: OTP_REQUEST_STATUS.NEW,
        name: 'Chưa xử lý'
    },
    // {
    //     id: OTP_REQUEST_STATUS.PROCESSING,
    //     name: 'Đang xử lý'
    // },
    {
        id: OTP_REQUEST_STATUS.DONE,
        name: 'Đã xử lý'
    }
]

class OtpRequestListTable extends Component {
    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: otpRequestStores.totalOtpRequestCount,
            page: otpRequestStores.otpRequestCurrentPage,
        };
        return paginationOption;
    }

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img alt='' className='img' style={{ width: 80, height: 80, objectFit: 'cover', borderRadius: 40 }} src={row.avatar || user_avatar_default} />
        </div>
    }

    onImageClick = (url) => () => {
        this.props.onOpenImageView([{ src: url }]);
    }

    imageFormatter = (cell, row) => {
        return (<div onClick={this.onImageClick(cell)} key={row.id} style={{ cursor: 'pointer' }} >
            <img alt='' className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={row.image} />
        </div>)
    }

    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    cell &&
                    <div>
                        <p>{moment(cell).format('hh:mm A')}</p>
                        <p>{moment(cell).format('DD/MM/YYYY')}</p>
                    </div>
                }

            </div>
        );
    }

    statusFormater = (cell, row) => {
        const buttonClassName = row.status == OTP_REQUEST_STATUS.DONE ? 'btn-success' : row.status == OTP_REQUEST_STATUS.PROCESSING ? 'btn-info' : 'btn-danger'
        return <div className="td-actions text-center">
            <Dropdown
                buttonClassName={buttonClassName}
                onItemClicked={this.onChangeStatus(row)}
                idSelected={row.status}
                datas={OTP_REQUEST_STATUS_LIST}
            />
        </div>
    }

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'phoneNumber',
                text: 'SDT',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'otp',
                text: 'OTP',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'createdAt',
                text: 'Thời gian gửi',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'adminFullname',
                text: 'Quản trị viên',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'responseAt',
                text: 'Phản hồi lúc',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'status',
                text: 'Trạng thái',
                formatter: this.statusFormater,
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80,
                    textAlign: 'center'
                }
            }
        ];
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 100,
        textAlign: 'center'
    })

    fullnameFormarter = (cell, row) => {
        return <a href={`#${row.userId}`} onClick={() => this.props.onShowUserInfo(row.userId)}>{row.fullname}</a>
    }

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        otpRequestStores.setOtpRequestPage(page);
        otpRequestStores.getOtpRequestListAction();
    }

    onChangeStatus = (row) => (item) => {
        if (row.status == item.id) return;
        otpRequestStores.updateStatusWorker(row.id, item.id);
    }

    render() {
        const otpRequests = toJS(otpRequestStores).otpRequestList;
        return (
            <PaginationTable
                paginationOptions={this.paginationOption()}
                onTableChange={this.onTableChange}
                datas={otpRequests}
                columns={this.columnsOption()}
                loading={otpRequestStores.loadingOtpRequestList}
            />
        );
    }
}

export default observer(OtpRequestListTable);