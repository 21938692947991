import React, { useRef, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { AcceptButton, CancelButton } from '../../components/Button';
import { no_image } from 'src/common/images';
import { COLOR } from 'src/common/constant';
import { Global } from 'src/GlobalClass';
import { uploadImageNoResize, AWS_IMAGE_BASE_URL, AWS_IMAGE_FOLDERS } from 'src/api/axios';
import schoolStore from '../stores/SchoolStore';

const SchoolTypes = [
  { id: 1, name: 'Cấp 1', type: 'C1' },
  { id: 2, name: 'Cấp 2', type: 'C2' },
  { id: 3, name: 'Cấp 3', type: 'C3' },
  { id: 4, name: 'Cao đẳng', type: 'CĐ' },
  { id: 5, name: 'Đại học', type: 'ĐH' },
  { id: 6, name: 'Viện nghiên cứu', type: 'VNC' },
  { id: 7, name: 'Khác', type: 'Other' },
]

const EditSchoolModal = ({
  isShow,
  school,
}) => {
  const inputFileRef = useRef(null);
  const [schoolType, setSchoolType] = useState(school ? school.type : undefined);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const [imageUrl, setImageUrl] = useState(school ? school.logo : undefined);
  const [schoolName, setSchoolName] = useState(school ? school.name : undefined);
  const [schoolDistrict, setSchoolDistrict] = useState(school ? school.district : undefined);
  const [schoolProvince, setSchoolProvince] = useState(school ? school.province : undefined);
  const [schoolAddress, setSchoolAddress] = useState(school ? school.address : undefined);
  const [schoolDesc, setSchoolDesc] = useState(school ? school.description : undefined);
  const [schoolShortName, setSchoolShortName] = useState(school ? school.shortName : undefined);
  const [schoolEnName, setSchoolEnName] = useState(school ? school.enName : undefined);
  const [schoolLat, setSchoolLat] = useState(school ? school.lat : undefined);
  const [schoolLng, setSchoolLng] = useState(school ? school.lng : undefined);
  const [errLinkMessage, setErrLinkMessage] = useState();

  useEffect(() => {
    if (school) {
      setSchoolType(school.type);
      setImageUrl(school.logo);
      setSchoolName(school.name);
      setSchoolDistrict(school.district);
      setSchoolProvince(school.province);
      setSchoolAddress(school.address);
      setSchoolDesc(school.description);
      setSchoolShortName(school.shortName);
      setSchoolEnName(school.enName);
      setSchoolLat(school.lat);
      setSchoolLng(school.lng);
      return () => { resetState() };
    }
  }, [school])

  useEffect(() => {
    if (!!imageUrl && !!schoolName && !!schoolDistrict && !!schoolProvince && !!schoolAddress && !!schoolType) {
      setDisabledSubmit(false);
      return;
    }
    if (!disabledSubmit) setDisabledSubmit(true);
  }, [imageUrl, schoolName, schoolDistrict, schoolProvince, schoolAddress, schoolType])

  const onPickImage = () => {
    if (inputFileRef.current) inputFileRef.current.click();
  }

  const onPickFileSuccess = async (e) => {
    let file = e.target.files[0];
    if (!file) return;
    if (!file.type.includes('image')) return Global.error({ message: 'Vui lòng chọn file định dạng ảnh !' });
    const formData = new FormData();
    formData.append("file", file);
    uploadImage(formData);
  }

  const uploadImage = async (form) => {
    try {
      const result = await uploadImageNoResize(form);
      if (result.data) {
        setImageUrl(result.data.imageName ? AWS_IMAGE_BASE_URL + AWS_IMAGE_FOLDERS.md + '/' + result.data.imageName : result.data.uploadedUrl);
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  const handleSubmit = async () => {
    const { updateSchool, createSchool } = schoolStore;
    if (errLinkMessage) setErrLinkMessage(undefined);
    try {
      if (!!school && !!school.id) {
        await updateSchool(
          school.id,
          {
            id: school.id,
            name: schoolName,
            logo: imageUrl,
            name: schoolName,
            district: schoolDistrict,
            province: schoolProvince,
            address: schoolAddress,
            description: schoolDesc,
            shortName: schoolShortName,
            enName: schoolEnName,
            lat: schoolLat,
            lng: schoolLng,
            type: schoolType
          })
      }
      else {
        await createSchool({
          name: schoolName,
          logo: imageUrl,
          name: schoolName,
          district: schoolDistrict,
          province: schoolProvince,
          address: schoolAddress,
          description: schoolDesc,
          shortName: schoolShortName,
          enName: schoolEnName,
          lat: schoolLat,
          lng: schoolLng,
          type: schoolType
        })
      }
    }
    catch (err) {
      Global.error({ messgae: 'Có lỗi xảy ra. Vui lòng thử lại' })
    }
  }

  const handleCancel = () => {
    onHideModal();
  }

  const resetState = () => {
    setSchoolType(undefined)
    setImageUrl(undefined)
  }

  const onHideModal = () => {
    const { getSchoolList, clearCurrentSchool, setShowModal } = schoolStore;
    setShowModal(false);
    getSchoolList();
    clearCurrentSchool();
  }

  return <Modal
    show={isShow}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    scrollable
    animation
    onHide={onHideModal}
  >
    <Modal.Header closeButton >
      <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
        Chỉnh sửa thông tin trường
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 16 }}>
        <img alt='' style={{ height: 300, maxWidth: '30vw', objectFit: 'contain', marginBottom: 6, marginTop: 16, border: `solid 0.5px ${COLOR.tertiary_light}` }}
          src={imageUrl || no_image} />
        <div className='td-actions text-center' style={{ marginLeft: 6 }}>
          <button onClick={onPickImage} type="button" rel="tooltip" className="btn btn-warning btn-simple">
            <i className="material-icons">edit</i>
          </button>
        </div>
      </div>
      <br />
      <div style={{ height: 16 }} />

      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >Tên trường(*)</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setSchoolName(e.target.value)} value={schoolName} />
      </div>

      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >Quận/Huyện(*)</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setSchoolDistrict(e.target.value)} value={schoolDistrict} />
      </div>

      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >TP/Tỉnh(*)</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setSchoolProvince(e.target.value)} value={schoolProvince} />
      </div>

      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >Địa chỉ(*)</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setSchoolAddress(e.target.value)} value={schoolAddress} />
      </div>

      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >Mô tả</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setSchoolDesc(e.target.value)} value={schoolDesc} />
      </div>

      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >Tên viết tắt</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setSchoolShortName(e.target.value)} value={schoolShortName} />
      </div>

      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >Tên tiếng Anh</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setSchoolEnName(e.target.value)} value={schoolEnName} />
      </div>

      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >Latitude</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setSchoolLat(e.target.value)} value={schoolLat} />
      </div>

      <div style={{ marginTop: 16, marginBottom: 16 }} >
        <label style={{ marginRight: 16, width: '15%' }} >Longitude</label>
        <input type='text' style={{ width: '40%', padding: 5 }} onChange={e => setSchoolLng(e.target.value)} value={schoolLng} />
      </div>

      <label
        style={{ marginRight: 16, width: '15%' }}
      >Chọn loại trường(*):</label>
      <select name="event" value={schoolType}
        onChange={e => {
          setSchoolType(e.target.value)
        }}
        style={{ padding: 5, width: '40%' }}
      >
        <option disabled value selected > -- Chọn một loại -- </option>
        {
          SchoolTypes.map(e => <option value={e.type} key={e.type} >{e.name}</option>)
        }
      </select>

      <input hidden accept="image/*" onChange={onPickFileSuccess} ref={inputFileRef} type='file' id='img-banner' />
    </Modal.Body>
    <Modal.Footer>
      <CancelButton onClick={handleCancel} title='Huỷ' />
      <AcceptButton disabled={disabledSubmit} onClick={handleSubmit} title={!!school && !!school.id ? 'Thay đổi' : 'Tạo mới'} />
    </Modal.Footer>
  </Modal>
}

export default EditSchoolModal;