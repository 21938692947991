import React, { Component, createRef } from 'react';
import KheBanComplainListTable from './components/KheBanComplainListTable';
import { kheBanComplainStore } from './stores/KheBanComplainStore';
import { userDetailStores } from '../UserManagement/stores/UserDetailStores';
import UserDetailModal from '../UserManagement/components/UserDetail/UserDetailModal';
import KheBanComplainFeedbackModal from './components/KheBanComplainFeedbackModal';

class KheBanComplainList extends Component {
    constructor(props) {
        super(props);
        this.kheBanComplainFeedbackModalRef = createRef();
    };

    onShowUserInfo = (userId) => {
        if (!userId) return;
        userDetailStores.setUserIdForLoadDetail(userId);
        userDetailStores.onChangeUserDetailModal(true);
    }

    onFeedbackIconPress = (userIdToSend) => {
        if (this.kheBanComplainFeedbackModalRef.current) {
            this.kheBanComplainFeedbackModalRef.current.settUserIdToSendFeedback(userIdToSend);
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <KheBanComplainListTable
                        onFeedbackIconPress={this.onFeedbackIconPress}
                        onShowUserInfo={this.onShowUserInfo}
                    />
                </div>
                <UserDetailModal />
                <KheBanComplainFeedbackModal
                    ref={this.kheBanComplainFeedbackModalRef} />
            </div>
        );
    }

    componentDidMount = async () => {
        kheBanComplainStore.getData();
    };

}

export default KheBanComplainList;