import React, { Component } from 'react';
import { observer } from 'mobx-react';
import OtpRequestResponseModal from './components/OtpRequestResponseModal';
import OtpRequestListTable from './components/OtpRequestListTable';
import { otpRequestStores } from './stores/OtpRequestStores';
import { Global } from '../../GlobalClass';
import UserDetailModal from '../UserManagement/components/UserDetail/UserDetailModal';
import { userDetailStores } from '../UserManagement/stores/UserDetailStores';
import ImgsViewer from 'react-images-viewer'


class OtpRequestList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currImg: 0,
            viewerIsOpen: false,
            imagesViewer: []
        }
    };

    onItemOtpRequestClicked = (item) => {
        if (!item.id) return Global.error({ message: 'Dữ liệu không hợp lệ.' });

        otpRequestStores.onChangeOtpRequestResponseModal(true);
        otpRequestStores.setIdOtpRequestToResponse(item)
    }
    onShowUserInfo = (userId) => {
        if (!userId) return;
        userDetailStores.setUserIdForLoadDetail(userId);
        userDetailStores.onChangeUserDetailModal(true);
    }

    gotoPrevious = () => this.setState({ currImg: this.state.currImg - 1 });
    gotoNext = () => this.setState({ currImg: this.state.currImg + 1 });
    closeViewer = () => this.setState({ viewerIsOpen: false });
    onOpenImageView = (images) => {
        this.setState({ imagesViewer: images, viewerIsOpen: true });
    }
    onClickThumbnail = (index) => this.setState({ currImg: index });

    render() {
        const { currImg, viewerIsOpen, imagesViewer } = this.state;
        return (
            <div className="card">
                <div className="card-body">
                    <OtpRequestListTable
                        onOpenImageView={this.onOpenImageView}
                        onItemClicked={this.onItemOtpRequestClicked}
                        onShowUserInfo={this.onShowUserInfo}
                    />
                </div>

                <OtpRequestResponseModal />
                <UserDetailModal />
                <ImgsViewer
                    onClickThumbnail={this.onClickThumbnail}
                    showThumbnails
                    backdropCloseable
                    imgs={imagesViewer}
                    currImg={currImg}
                    isOpen={viewerIsOpen}
                    onClickPrev={this.gotoPrevious}
                    onClickNext={this.gotoNext}
                    onClose={this.closeViewer}
                />
            </div>
        );
    }

    componentDidMount = async () => {
        await otpRequestStores.getOtpRequestTotalCountAction();
        otpRequestStores.getOtpRequestListAction();
    };

}

export default observer(OtpRequestList);