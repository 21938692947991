import { extendObservable } from 'mobx';
import { pageableOffset } from '../../../helpers/CF';
import { getKheReportList, banUserApi, updateKheProfileStatusApi, sendFeedbackKheReportApi } from '../../../api/kheReport/kheReport';
import { Global } from '../../../GlobalClass';
class KheReportStore {
    constructor() {
        extendObservable(this, {
            reportlist: [],
            loading: false,
            currentPage: 1,
            isShowFeedbackModal: false,
            sendFeedbackLoading: false
        });
    }


    getData = async () => {
        try {
            this.loading = true;
            const offset = pageableOffset(this.currentPage);
            const result = await getKheReportList(offset);
            if (result.responseCode.toString().startsWith('2')) {
                this.reportlist = result.dataArray || [];
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
        this.loading = false;
    }

    updateReportStatusWorker = async (id, status) => {
        this.loading = true;
        try {
            const result = await updateKheProfileStatusApi(id, status);
            if (result.responseCode.toString().startsWith('2')) {
                const index = this.reportlist.findIndex(o => o.id == id);
                if (index > -1) {
                    this.reportlist[index].status = status;
                }
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
        this.loading = false;
    }

    sendFeedbackReportWorker = async (content, userIdToSend, metadata) => {
        try {
            this.changeFeedbackModalLoadingWorker(true);
            const result = await sendFeedbackKheReportApi(content, userIdToSend, metadata);
            if (result.responseCode.toString().startsWith('2')) return true;
            else Global.error({ message: result.responseText || '' });
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
        this.changeFeedbackModalLoadingWorker(false);
        return false;
    }

    // onChangeFeedbackResponseModal = (status) => this.isShowFeedbackResponseModal = status;

    // setIdFeedbackToResponse = (feedbackId) => this.idFeedbackToResponse = feedbackId;

    setPage = (page) => this.currentPage = page;

    changeFeedbackModalStatusWorker = (status) => this.isShowFeedbackModal = status;
    changeFeedbackModalLoadingWorker = (status) => this.sendFeedbackLoading = status;

    // responseFeedbackAction = async (message) => {
    //     const result = await responseFeedbackApi(this.idFeedbackToResponse, message);
    //     if (result.responseCode.toString().startsWith('2')) this.getFeedbackListAction();
    //     else Global.error({ message: result.responseText || '' });
    // }

    banUser = async (userId) => {
        try {
            this.changeFeedbackModalLoadingWorker(true);
            const result = await banUserApi(userId);
            if (result.responseCode.toString().startsWith('2')) {
                const currList = [...this.reportlist];
                const index = currList.findIndex(o => o.id == userId);
                if (index > -1) {
                    currList.splice(index, 1);
                    this.reportlist = currList;
                }

                this.changeFeedbackModalLoadingWorker(false);
                return true;
            } else Global.error({ message: result.responseText || '' });
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
            this.changeFeedbackModalLoadingWorker(false);
        }

        return false;
    }
}
export const kheReportStore = new KheReportStore();