import React, { useEffect, useState, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import need_buy from '@img/need_buy.png';
import { useForm } from "react-hook-form";
import { shortenPrice } from '@helpers/shortenPrice';
import { uploadImageNoResize, AWS_IMAGE_BASE_URL, AWS_IMAGE_FOLDERS } from '@api/axios';
import { getFullImagePath } from '@helpers/getFullImagePath';
import { Global } from 'src/GlobalClass';
import { createNewTeccoSaleProduct } from '@api/pushSale/pushSale';
import _ from 'lodash';
import './styles.css';

const MAX_LENGTH = 1024;

const PushSaleEditorModal = ({
  isShowing,
  closeModal,
  categories,
  selectedProduct,
  resetSelectedProduct,
  categoryImages,
  reloadData
}) => {
  const { register, handleSubmit, getValues, errors, watch, reset, setValue } = useForm();
  const [description, setDescription] = useState('');
  const [imageText, setImageText] = useState('');
  const [price, setPrice] = useState('');
  const [imageName, setImageName] = useState();
  const [cateImage, setCateImage] = useState();

  const inputFileRef = useRef(null);

  useEffect(() => {
    if (selectedProduct) {
      setValue('title', selectedProduct.title || '');
      setValue('imageText', selectedProduct.imageText || '');
      setValue('description', selectedProduct.description || '');
      setValue('cateId', selectedProduct.defaultCateId || '');
      setValue('price', selectedProduct.price);
      setDescription(selectedProduct.description);
      setImageText(selectedProduct.imageText);
      setPrice(selectedProduct.price);
      setImageName(selectedProduct.imageName);
      const selectedCate = categoryImages && _.find(categoryImages, { cateId: selectedProduct.defaultCateId });
      if (selectedCate) setCateImage(selectedCate.imageName);
    }
    else {
      categoryImages.length > 0 && setCateImage(categoryImages[0].imageName);
    }
  }, [selectedProduct, categoryImages])

  const handleCloseModal = () => {
    resetState();
    resetSelectedProduct();
    closeModal();
  }

  const resetState = () => {
    reset();
    setDescription('');
    setImageName('');
    setPrice('');
    setImageText('');
    categoryImages.length > 0 && setCateImage(categoryImages[0].imageName);
  }

  const onPickImage = () => {
    if (inputFileRef.current) inputFileRef.current.click();
  }

  const onPickFileSuccess = async (e) => {
    let file = e.target.files[0];
    if (!file) return;
    if (!file.type.includes('image')) return Global.error({ message: 'Vui lòng chọn file định dạng ảnh !' });
    const formData = new FormData();
    formData.append("file", file);
    uploadImage(formData);
  }

  const uploadImage = async (form) => {
    try {
      const result = await uploadImageNoResize(form);
      if (result.data) {
        setImageName(result.data.imageName);
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const deleteUserPickedImage = () => setImageName(undefined);

  const onSubmit = async (data) => {
    const payloadSubmit = { ...data, cateId: parseInt(data.cateId) }
    // console.log("payloadSubmit: " + JSON.stringify(payloadSubmit));
    try {
      const result = await createNewTeccoSaleProduct(payloadSubmit);
      if (result.responseCode.toString().startsWith('2')) {
        // reloadData();
        Global.success({ message: 'Tạo sản phẩm thành công' });
        handleCloseModal();
      }
      else Global.error({ message: 'Có lỗi xảy ra. Vui lòng thử lại' })
    }
    catch (err) {
      Global.error({ message: 'Có lỗi xảy ra. Vui lòng thử lại' })
    }
  };

  return <div>
    <Modal
      className='push-sale-editor-modal'
      show={isShowing}
      size='xl'
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
      animation
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton onHide={handleCloseModal} >
        <Modal.Title style={{ fontWeight: 'bold' }} id="contained-modal-title-vcenter">
          {'Tạo mặt hàng Tecco'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)} >
          <label className='label'>Loại mặt hàng</label>
          <select
            className='inputView'
            name="cateId"
            ref={register({ required: true })}
            onChange={e => {
              const selectedCate = categoryImages.length > 0 && _.find(categoryImages, cate => cate.cateId == e.target.value);
              if (selectedCate) setCateImage(selectedCate.imageName);
              if (selectedProduct.imageName && !!_.find(categoryImages, { imageName: imageName })) setImageName(undefined);
            }}
          >
            {categories && categories.map((cat, index) => <option
              value={cat.id}
              key={cat.id.toString()}
            >{cat.categoryName}</option>)}
          </select>
          {errors.cateId && <span style={{ color: 'red' }} >Ô này không được bỏ trống</span>}
          <label className='label'>Ảnh mặt hàng</label>
          <input className='inputView' name="imageUrl" ref={register({ required: true })} />
          {errors.imageUrl && <span style={{ color: 'red' }} >Ô này không được bỏ trống</span>}
          <label className='label'>Tên mặt hàng</label>
          <input className='inputView' name="title" ref={register({ required: true })} />
          {errors.title && <span style={{ color: 'red' }} >Ô này không được bỏ trống</span>}
          <label className='label'>SDT</label>
          <input className='inputView' name="phone" ref={register({ required: true })} />
          {errors.phone && <span style={{ color: 'red' }} >Ô này không được bỏ trống</span>}
          <label className='label'>Giá</label>
          <input
            className='inputView'
            name="price"
            type='number'
            ref={register({ required: true })}
            onChange={e => setPrice(e.target.value)}
          />
          {errors.price && <span style={{ color: 'red' }} >Ô này không được bỏ trống</span>}
          <label className='label'>Mô tả</label>
          <textarea className='inputView' name="description" ref={register} rows={5} onChange={e => setDescription(e.target.value)} maxLength={MAX_LENGTH} />
          {errors.description && <span style={{ color: 'red' }} >Ô này không được bỏ trống</span>}
          <input className='inputView' type="submit" value={selectedProduct ? 'Thay đổi' : 'Tạo mới'} />
        </form>
        <input className='inputView' hidden accept="image/*" onChange={onPickFileSuccess} ref={inputFileRef} type='file' id='img-banner' />
      </Modal.Body>

    </Modal>
  </div>
}

export default PushSaleEditorModal;