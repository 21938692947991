import { extendObservable, toJS } from 'mobx';
import { pageableOffset } from '../../../helpers/CF';
import { getOtpRequestListApi, getOtpRequestTotalCountApi, responseOtpRequestApi, updateOtpRequestStatusApi } from '../../../api/otpRequest/otpRequest';
import { Global } from '../../../GlobalClass';
class OtpRequestStores {
    constructor() {
        extendObservable(this, {
            otpRequestList: [],
            loadingOtpRequestList: false,
            totalOtpRequestCount: 0,
            otpRequestCurrentPage: 1,
            isShowOtpRequestResponseModal: false,
            idOtpRequestToResponse: 0,
            otpRequestCurentItem: {}
        });
    }

    getOtpRequestTotalCountAction = async () => {
        const result = await getOtpRequestTotalCountApi();
        if (result.responseCode.toString().startsWith('2')) {
            this.totalOtpRequestCount = parseInt(result.data.total);
        } else {
            Global.error({ message: result.responseText || '' });
        }
    }

    getOtpRequestListAction = async () => {
        this.loadingOtpRequestList = true;
        const offset = pageableOffset(this.otpRequestCurrentPage);
        const result = await getOtpRequestListApi(offset);
        if (result.responseCode.toString().startsWith('2')) {
            this.otpRequestList = result.dataArray || [];
        } else {
            Global.error({ message: result.responseText || '' });
        }
        this.loadingOtpRequestList = false;
    }

    updateStatusWorker = async (id, status) => {
        this.loadingOtpRequestList = true;
        try {
            const result = await updateOtpRequestStatusApi(id, status);
            if (result.responseCode.toString().startsWith('2')) {
                const index = toJS(this.otpRequestList).findIndex(o => o.id == id);
                if (index > -1) {
                    this.otpRequestList[index].status = status;
                }
            } else {
                Global.error({ message: result.responseText || '' });
            }
        } catch (error) {
            Global.error({ message: error.message || 'Có lỗi xảy ra.' });
        }
        this.loadingOtpRequestList = false;
    }

    onChangeOtpRequestResponseModal = (status) => this.isShowOtpRequestResponseModal = status;

    setIdOtpRequestToResponse = (item) => {
        this.idOtpRequestToResponse = item.id;
        this.otpRequestCurentItem = item;
    }

    setOtpRequestPage = (page) => this.otpRequestCurrentPage = page;

    responseOtpRequestAction = async (message) => {
        const result = await responseOtpRequestApi(this.idOtpRequestToResponse, message);
        if (result.responseCode.toString().startsWith('2')) this.getOtpRequestListAction();
        else Global.error({ message: result.responseText || '' });
    }
}
export const otpRequestStores = new OtpRequestStores();