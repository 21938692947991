import React, { Component } from 'react';
import { AcceptButton } from '../components/Button';
import _ from 'lodash'

import SchoolRequestTable from './components/SchoolRequestTable';
import schoolRequestStore from './stores/SchoolRequestStore';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

class ProductReportList extends Component {
  constructor(props) {
    super(props);
    this.onChangeTextDebounce = _.debounce(this.onChangeTextDebounce.bind(this), 600);
  }
  onCreateClick = () => {
    schoolRequestStore.setShowModal(true);
  }

  async onChangeTextDebounce() {
    const { getSchoolRequestCount, setTableCurrentPage, getSchoolRequestList } = schoolRequestStore;
    await getSchoolRequestCount();
    setTableCurrentPage(1);
    getSchoolRequestList();
  }

  onChangeText = async (e) => {
    const { onTextSearchChange } = schoolRequestStore;
    onTextSearchChange(e.target.value);
    this.onChangeTextDebounce();
  }
  render() {
    const { txtSearch } = toJS(schoolRequestStore);
    console.log(txtSearch)
    return (
      <div className="card">
        <div className="card-body">
          <div className='row justify-content-end' style={{ margin: 0, flex: 1, paddingRight: 16 }}>
            <AcceptButton onClick={this.onCreateClick} containerStyle={{ height: 'fit-content' }} title='Tạo mới' />
          </div>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className='material-icons'>search</i>
              </span>
            </div>
            <input
              style={{ marginBottom: 12 }}
              onChange={this.onChangeText}
              type="text"
              value={txtSearch}
              className="form-control"
              placeholder="Tìm kiếm theo tên trường..." />
          </div>
          <SchoolRequestTable />
        </div>
      </div>
    )
  }

  componentDidMount() {
    schoolRequestStore.getSchoolRequestList();
    schoolRequestStore.getSchoolRequestCount();
  }
}

export default observer(ProductReportList)