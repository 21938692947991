import React, { Component, createRef } from 'react';
import KheReportListTable from './components/KheReportListTable';
import { kheReportStore } from './stores/KheReportStore';
import { userDetailStores } from '../UserManagement/stores/UserDetailStores';
import UserDetailModal from '../UserManagement/components/UserDetail/UserDetailModal';
import KheReportFeedbackModal from './components/KheReportFeedbackModal';

class KheReportList extends Component {
    constructor(props) {
        super(props);
        this.kheReportFeedbackModalRef = createRef();
    };

    onShowUserInfo = (userId) => {
        if (!userId) return;
        userDetailStores.setUserIdForLoadDetail(userId);
        userDetailStores.onChangeUserDetailModal(true);
    }

    onFeedbackIconPress = (userIdToSend) => {
        if (this.kheReportFeedbackModalRef.current) {
            this.kheReportFeedbackModalRef.current.settUserIdToSendFeedback(userIdToSend);
        }
    }

    render() {
        return (
            <div className="card">
                <div className="card-body">
                    <KheReportListTable
                        onFeedbackIconPress={this.onFeedbackIconPress}
                        onShowUserInfo={this.onShowUserInfo}
                    />
                </div>
                <UserDetailModal />
                <KheReportFeedbackModal
                    ref={this.kheReportFeedbackModalRef} />
            </div>
        );
    }

    componentDidMount = async () => {
        kheReportStore.getData();
    };

}

export default KheReportList;