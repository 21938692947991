import React, { Component } from 'react';
import PaginationTable from '../../components/PaginationTable';
import { kheProfileReportStore } from '../stores/KheProfileReportStore';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import Dropdown from '../../components/Dropdown';
import moment from 'moment'
import {
    Link
} from "react-router-dom";

const REPORT_STATUS = {
    NEW: 'NEW',
    PROCESSING: 'PROCESSING',
    DONE: 'DONE'
}

const REPORT_STATUS_LIST = [
    {
        id: REPORT_STATUS.NEW,
        name: 'Chưa xử lý'
    },
    {
        id: REPORT_STATUS.PROCESSING,
        name: 'Đang xử lý'
    },
    {
        id: REPORT_STATUS.DONE,
        name: 'Đã xử lý'
    }
]
class ReportListTable extends Component {
    paginationOption = () => {
        const paginationOption = {
            custom: true,
            hideSizePerPage: true,
            totalSize: kheProfileReportStore.totalFeedbackCount,
            page: kheProfileReportStore.feedbackCurrentPage,
        };
        return paginationOption;
    }

    thumbFormatter = (cell, row) => {
        return <div key={row.id} className="card-avatar">
            <img alt='' className='img' style={{ width: 80, height: 80 }} src={cell} />
        </div>
    }

    // lockProduct = (cell, row) => {
    //     return <div className="td-actions text-center">
    //         {
    //             !row.statusFeedback ?
    //                 <span className="badge badge-pill badge-danger">Chưa trả lời</span>
    //                 :
    //                 <span className="badge badge-pill badge-success">Đã trả lời</span>
    //         }
    //     </div>
    // }
    dateFormatter = (cell, row, rowIndex, formatExtraData) => {
        return (
            <div>
                {
                    cell &&
                    <div>
                        <p>{moment(cell).format('hh:mm A')}</p>
                        <p>{moment(cell).format('DD/MM/YYYY')}</p>
                    </div>
                }

            </div>
        );
    }
    // detailFormatter = (cell, row, rowIndex, formatExtraData) => {
    //     return (
    //         <div>
    //             <Link target="_blank" to={'https://khelilac.com/forumthread?id=' + row.threadId}> <button class="btn btn-primary" onClick={() => {
    //             }}>Chi tiết</button></Link>
    //         </div>
    //     );
    // }

    // detailFormatter = (cell, row) => {
    //     return <div className="td-actions text-center" style={{ justifyContent: 'center' }}>
    //         <a target="_blank" href={row.dynamicLink}>
    //             <i className="material-icons">info</i>
    //         </a>
    //     </div>
    // }

    // onDeleteGroup = (groupId) => {
    //     let confirmAlert = window.confirm('Ban có chắc chắn muốn xóa?');
    //     if (confirmAlert) {
    //         kheProfileReportStore.deleteGroupWorker(groupId);
    //     }
    // }

    // actionButtons = (cell, row) => {
    //     return <div className="td-actions text-center">
    //         <button onClick={() => this.onDeleteGroup(row.groupId)} style={{ marginRight: 6 }} type="button" rel="tooltip" className="btn btn-info btn-danger">
    //             <i className="material-icons">delete</i>
    //         </button>
    //         {/* <a target="_blank" href={row.dynamicLink}>
    //             <i className="material-icons">info</i>
    //         </a> */}
    //     </div>
    // };

    fullnameFormarter = (cell, row) => {
        return <div className='text-center'>
            <span>
                <a href={`#${row.toUserId}`} onClick={() => this.props.onShowUserInfo(row.toUserId)}>{row.toUserFullname}</a>
            </span>
            <div className="td-actions text-center">
                <button onClick={this.onFeedbackIconPress(row.toUserId)} style={{ marginTop: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                    <i className="material-icons">feedback</i>
                </button>
            </div>
        </div>
    }
    onFeedbackIconPress = (userIdToFeedback) => () => {
        kheProfileReportStore.changeFeedbackModalStatusWorker(true);
        this.props.onFeedbackIconPress(userIdToFeedback);
    }

    fullnameFormarter2 = (cell, row) => {
        return <div className='text-center'>
            <span>
                <a href={`#${row.fromUserId}`} onClick={() => this.props.onShowUserInfo(row.fromUserId)}>{row.fromUserFullName}</a>
            </span>
            <div className="td-actions text-center">
                <button onClick={this.onFeedbackIconPress(row.fromUserId)} style={{ marginTop: 6 }} type="button" rel="tooltip" className="btn btn-info btn-simple">
                    <i className="material-icons">feedback</i>
                </button>
            </div>
        </div>
    }

    onChangeStatus = (row) => (item) => {
        if (row.status == item.id) return;
        kheProfileReportStore.updateReportStatusWorker(row.id, item.id);
    }

    statusFormater = (cell, row) => {
        const buttonClassName = row.status == REPORT_STATUS.DONE ? 'btn-success' : row.status == REPORT_STATUS.PROCESSING ? 'btn-info' : 'btn-danger'
        return <div className="td-actions text-center">
            <Dropdown
                buttonClassName={buttonClassName}
                onItemClicked={this.onChangeStatus(row)}
                idSelected={row.status}
                datas={REPORT_STATUS_LIST}
            />
        </div>
    }

    columnsOption = () => {
        return [
            {
                dataField: 'id',
                text: '#',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 40
                }
            },
            {
                dataField: 'message',
                text: 'Tin nhắn',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 80
                }
            },
            {
                dataField: 'reportedUserId',
                text: 'Người báo cáo',
                headerFormatter: this.headerFormatter,
                // formatter: this.fullnameFormarter2,
                headerStyle: {
                    width: 60
                }
            },
            {
                dataField: 'createdAt',
                text: 'Báo cáo lúc',
                headerFormatter: this.headerFormatter,
                formatter: this.dateFormatter,
                headerStyle: this.headerStyleDefault
            },
            {
                dataField: 'userId',
                text: 'Người bị báo cáo',
                headerFormatter: this.headerFormatter,
                headerStyle: {
                    width: 60
                }
            },
            // {
            //     dataField: 'status',
            //     text: 'Trạng thái',
            //     formatter: this.statusFormater,
            //     headerFormatter: this.headerFormatter,
            //     headerStyle: {
            //         width: 80,
            //         textAlign: 'center'
            //     }
            // },
            // {
            //     dataField: 'view',
            //     text: 'Chi tiết bài viết',
            //     formatter: this.detailFormatter,
            //     headerStyle: {
            //         width: 48,
            //         textAlign: 'center'
            //     }
            // },
            // {
            //     dataField: 'groupAction',
            //     text: 'Xoá nhóm',
            //     formatter: this.actionButtons,
            //     headerStyle: {
            //         width: 48,
            //         textAlign: 'center'
            //     }
            // }
        ];
    }

    headerStyleDefault = (column, colIndex) => ({
        width: 60,
        textAlign: 'left'
    })

    headerFormatter = (column, colIndex) => {
        return <h5><strong>{column.text}</strong></h5>
    }

    onTableChange = (type, { page, sizePerPage }) => {
        window.scrollTo(0, 0);
        kheProfileReportStore.setPage(page);
        kheProfileReportStore.getData();
    }

    render() {
        const reports = toJS(kheProfileReportStore).reportlist;
        return (
            <PaginationTable
                paginationOptions={this.paginationOption()}
                onTableChange={this.onTableChange}
                datas={reports}
                columns={this.columnsOption()}
                loading={kheProfileReportStore.loading}
            />
        );
    }
}

export default observer(ReportListTable);