import { requestApi } from "../axios";
const prefix = 'khe-report/complain';

export function getKheBanComplainList(offset) {
    return requestApi(`${prefix}/list?limit=${10}&offset=${offset}`, 'GET');
}

export function unbanUserApi(userId) {
    return requestApi(`/ban/unban-account`, 'POST', { userId });
}

export function unbanDeviceApi(deviceId) {
    return requestApi(`/ban/unban-device`, 'POST', { deviceId });
}

export function updateKheProfileStatusApi(id, status) {
    return requestApi(`${prefix}/update-report-status`, 'PUT', { id, status });
}

export function sendFeedbackKheBanComplainApi(content, userIdToSend, metadata) {
    return requestApi(`${prefix}/send-feedback-report`, 'POST', {
        content, userIdToSend, metadata
    });
}