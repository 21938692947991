import { requestApi } from "../axios";
import {stringify} from 'querystring';
const prefix = 'school-request';

export function getSchoolRequestList(search, limit, offset) {
    return requestApi(`${prefix}/getList?${stringify({search, limit, offset})}`, 'GET');
}

export function getSchoolRequestCount(search) {
    return requestApi(`${prefix}/count?${stringify({search})}`, 'GET');
}

export function getSchoolRequestDetails(schoolRequestId) {
  return requestApi(`${prefix}/${schoolRequestId}`, 'GET');
}

export function createSchoolRequest(payload) {
  return requestApi(`${prefix}`, 'POST', payload);
}

export function approveSchoolRequest(schoolRequestId, payload) {
  return requestApi(`${prefix}/approve/${schoolRequestId}`, 'POST', payload);
}

export function rejectSchoolRequest(schoolRequestId, payload) {
  return requestApi(`${prefix}/reject/${schoolRequestId}`, 'POST', payload);
}