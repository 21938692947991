import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { user_avatar_default } from 'src/common/images';
import PaginationTable from '../../components/PaginationTable';
import schoolStore from '../stores/SchoolStore';
import EditSchoolModal from './EditSchoolModal';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { COLOR } from 'src/common/constant';
import Loader from 'react-loader-spinner';
import { CancelButton, AcceptButton } from 'src/pages/components/Button';
import { toJS } from 'mobx';

class SchoolTable extends Component {
  constructor() {
    super();
    this.state= {
      isShowConfirmDelSchool: false
    }
  }
  headerFormatter = (column) => {
    return <h5><strong>{column.text}</strong></h5>
  }

  formatterImage = (cell, row) => {
    return <div key={row.id} className="card-avatar">
      <img className='img' style={{ width: 80, height: 80, objectFit: 'cover' }} src={cell || user_avatar_default} />
    </div>
  }

  dateTimeFormatter = (cell) => {
    return (
      <div>
        {
          cell &&
          <div>
            <p>{moment(cell).format('DD/MM/YYYY')}</p>
            <p>{moment(cell).format('hh:mm A')}</p>
          </div>
        }

      </div>
    );
  }

  onEditClick = async schoolId => {
    const {getSchoolDetails, setShowModal} = schoolStore;
    await getSchoolDetails(schoolId);
    setShowModal(true);
  }

  onDeleteClick = async schoolId => {
    const {getSchoolDetails} = schoolStore;
    await getSchoolDetails(schoolId);
    this.setState({
      isShowConfirmDelSchool: true,
    })
  }

  actionButtons = (cell, row) => {
    return <div className="td-actions text-center">
      <button onClick={e => this.onEditClick(row.id)} style={{ marginRight: 6 }} type="button" className="btn btn-info btn-simple">
        <i className="material-icons">edit</i>
      </button>
      <button disabled={row.members > 0 || row.reviews > 0} onClick={e => this.onDeleteClick(row.id)} style={{ marginRight: 6 }} type="button" className="btn btn-danger btn-simple">
        <i className="material-icons">clear</i>
      </button>
    </div >
  }

  paginationOption = () => (
    {
        custom: true,
        hideSizePerPage: true,
        totalSize: schoolStore.schoolCount,
        page: schoolStore.tableCurrentPage,
    }
  )

  onTableChange = (type, { page, sizePerPage }) => {
    window.scrollTo(0, 0);
    schoolStore.setTableCurrentPage(page);
    schoolStore.getSchoolList();
  }

  columnsOption = [
    {
      dataField: 'id',
      text: '#',
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 40
      }
    },
    {
      dataField: 'name',
      text: 'Tên',
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 100,
      }
    },
    {
      dataField: 'shortAddress',
      text: 'Quận/Huyện',
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 80,
      }
    },
    {
      dataField: 'type',
      text: 'Loại',
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 30,
      }
    },
    {
      dataField: 'members',
      text: 'Members',
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 40,
      }
    },
    {
      dataField: 'reviews',
      text: 'Reviews',
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 40,
      }
    },
    // {
    //   dataField: 'background',
    //   text: 'Hình ảnh',
    //   headerFormatter: this.headerFormatter,
    //   formatter: this.formatterImage,
    //   headerStyle: {
    //     width: 80
    //   }
    // },
    // {
    //   dataField: 'startDate',
    //   text: 'Ngày bắt đầu',
    //   headerFormatter: this.headerFormatter,
    //   formatter: this.dateTimeFormatter,
    //   headerStyle: {
    //     width: 100
    //   }
    // },
    // {
    //   dataField: 'endDate',
    //   text: 'Ngày kết thúc',
    //   headerFormatter: this.headerFormatter,
    //   formatter: this.dateTimeFormatter,
    //   headerStyle: {
    //     width: 100
    //   }
    // },
    {
      dataField: 'action',
      text: 'Hành động',
      formatter: this.actionButtons,
      headerFormatter: this.headerFormatter,
      headerStyle: {
        width: 80,
        textAlign: 'center'
      }
    },
  ]

  onHideConfirmDelSchool = () => {
    this.setState({isShowConfirmDelSchool: false})
  }

  onConfirmDelSchool = async () => {
    const { currentSchool, deleteSchool } = schoolStore;
    if (!!currentSchool && !!currentSchool.id) {
      await deleteSchool(
        currentSchool.id,
        { id: currentSchool.id }
      )
    }
    this.setState({
      isShowConfirmDelSchool: false,
    })
  }

  render() {
    const { schoolList, isLoadingGetSchoolList, currentSchool, isShowModal, isLoadingUpdate } = schoolStore;
    const { isShowConfirmDelSchool } = this.state;
    return (
      <div>
        <Modal show={isShowConfirmDelSchool} onHide={this.onHideConfirmDelSchool}>
          <Modal.Header closeButton>
            <Modal.Title>
              <span>Bạn muốn xóa trường <b>{currentSchool ? currentSchool.name + '(' + currentSchool.id + ')' : ''}</b>?</span></Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            {isLoadingUpdate ?
              <Loader
                style={{ marginRight: '12px', marginTop: '8px' }}
                type="TailSpin"
                color={COLOR.primary}
                height={30}
                width={30}
              />
              : <div>
                <CancelButton title='HỦY BỎ' onClick={this.onHideConfirmDelSchool} />
                <AcceptButton title='XÁC NHẬN' onClick={this.onConfirmDelSchool} />
              </div>
            }
          </Modal.Footer>
        </Modal>
        <PaginationTable
          paginationOptions={this.paginationOption()}
          onTableChange={this.onTableChange}
          datas={schoolList}
          columns={this.columnsOption}
          loading={isLoadingGetSchoolList}
        />
        <EditSchoolModal
          isShow={isShowModal}
          onHide={this.onHideModal}
          school={currentSchool}
        />
      </div>
    )
  }
}

export default observer(SchoolTable);